import React, { Component } from 'react';

// CSS.
import './SideMenuItem.css';

class SideMenuHeader extends Component {

    onItemClicked = () => {
        this.props.onItemClicked(this.props.index);
    }

    render() {
        return (
            <div className='side-menu-item' onClick={ this.onItemClicked }>
                <div className='side-menu-item-container'>
                    <img src={ this.props.src } className='side-menu-item-img' alt='item'/>
                    <p className='no-margin no-padding side-menu-item-text'><b>{ this.props.text }</b></p>
                </div>
            </div>
        );
    }
}

export default SideMenuHeader;