import React, { Component } from 'react';

// CSS.
import './Spots.css';

// Services.
import languageService from '../../utils/language/language';

// Constants.
import constants from '../../utils/constants';

// Components.
import { Table, Button } from 'react-bootstrap';
import ContentHeader from '../content-header/ContentHeader';
import SpotModal from '../modals/spot/SpotModal';

class Spots extends Component {
    isLoadingContent = false;

    constructor() {
        super();

        this.state = {
            showSpotModal : false,
            mode : constants.MODE_READ,
            modalTitle : constants.EMPTY_STRING,
            categories : null,
            selectedSpot : null,
            spotPictures : null,
            spotCategories : null,
            showMoreSpot : false,
            filter : constants.EMPTY_STRING,
        }
    }

    // Method that is called when the user clicks the new button.
    onNewSpotButtonClicked = async () => {
        await this.props.getAllCategories(0);
        const categories = this.props.categories.getAll.data.list;

        categories.forEach(category => category.selected = false);

        this.setState({ showSpotModal : true, mode : constants.MODE_INSERT, modalTitle : languageService.strings.new_spot[languageService.getLanguage()], categories : categories, selectedSpot : null, spotPictures : null, spotCategories : null });
    }

    // Method that is called when the user clicks a spot to view.
    onSpotClicked = async (index) => {
        await this.props.getSpotPictures(this.props.spots.getAll.data.list[index].id, 0);
        await this.props.getSpotCategories(this.props.spots.getAll.data.list[index].id, 0);
        await this.props.getAllCategories(0);

        const categories = this.props.categories.getAll.data.list;
        const spotCategories = this.props.spotCategories.getAllBySpotId.data.list;

        for (let i = 0; i < categories.length; i++) {
            categories[i].selected = false;

            for (let j = 0; j < spotCategories.length; j++) {
                if (categories[i].id === spotCategories[j].categoryId) {
                    categories[i].selected = true;
                    break;
                }
            }
        }

        this.setState({ showSpotModal : true, mode : constants.MODE_READ, modalTitle : languageService.strings.view_spot[languageService.getLanguage()], categories : categories, selectedSpot : this.props.spots.getAll.data.list[index], spotPictures : this.props.spotPictures.getAllBySpotId.data.list, spotCategories : spotCategories });
    }

    // Method that is called when the user clicks a spot edition button.
    onEditSpotButtonClicked = async (index) => {
        await this.props.getSpotPictures(this.props.spots.getAll.data.list[index].id, 0);
        await this.props.getSpotCategories(this.props.spots.getAll.data.list[index].id, 0);
        await this.props.getAllCategories(0);

        const categories = this.props.categories.getAll.data.list;
        const spotCategories = this.props.spotCategories.getAllBySpotId.data.list;

        for (let i = 0; i < categories.length; i++) {
            categories[i].selected = false;

            for (let j = 0; j < spotCategories.length; j++) {
                if (categories[i].id === spotCategories[j].categoryId) {
                    categories[i].selected = true;
                    break;
                }
            }
        }
        this.setState({ showSpotModal : true, mode : constants.MODE_EDIT, modalTitle : languageService.strings.edit_spot[languageService.getLanguage()], categories : categories, selectedSpot : this.props.spots.getAll.data.list[index], spotPictures : this.props.spotPictures.getAllBySpotId.data.list, spotCategories : spotCategories });
    }

    // Method that is called when the user clicks the delete spot button.
    onDeleteSpotButtonClicked = (index) => {
        this.props.onDeletePlace(index, this.props.spots.getAll.data.list[index].id);
    }

    // Method that closes the spot modal.
    onCloseSpotModalClicked = () => {
        this.setState({ showSpotModal : false, selectedSpot : null });
    }

    // Method that loads more spots.
    onLoadMoreSpotsButtonClicked = async () => {
        const lastElementId = this.props.spots.getAll.data.list[this.props.spots.getAll.data.list.length - 1].id;

        await this.props.getAllSpots(lastElementId, this.state.filter);
        
        this.setState({ showMoreSpots : false });

        const element = document.getElementById(constants.SPOTS + lastElementId);
        element.scrollIntoView();
    }

    // Method that sets a spot (post/patch).
    setSpot = async (spot, spotPictures, deletedSpotPictures, spotCategories, deletedSpotCategories) => {
        switch(this.state.mode) {
            case constants.MODE_INSERT: return await this.props.onPostSpot(spot, spotPictures, spotCategories);
            case constants.MODE_EDIT: return await this.props.onPatchSpot(spot, spotPictures, deletedSpotPictures, spotCategories, deletedSpotCategories);
            default: break;
        }
    }

    // Method that is called when the user scrolls the table.
    onTableScrolled = () => {
        const tableBody = document.getElementById(constants.SPOTS_TABLE_BODY);

        if (!this.isLoadingContent && this.props.spots.getAll.data.loadMore && tableBody.scrollTop + tableBody.clientHeight >= (tableBody.scrollHeight * 0.95)) {
            this.setState({ showMoreSpots : true });
        }
    }

    // Method that is called to get all spots.
    getAllSpots = async (lastElementId, filter) => {
        this.setState({ filter : filter });
        return await this.props.getAllSpots(lastElementId, filter);
    }

    // Method that renders the content.
    renderContent = () => {
        return (
            <div>
                <Table striped bordered hover className='no-margin no-padding'>
                    <thead>
                        <tr id={ constants.HEADER_ROW }>
                            <th className='cell-w100 center-text'>#</th>
                            <th>{ languageService.strings.name[languageService.getLanguage()] }</th>
                            <th className='no-border-right'>{ languageService.strings.address[languageService.getLanguage()] }</th>
                            <th className='cell-w50'></th>
                            <th className='cell-w50'></th>
                        </tr>
                    </thead>
                    <tbody onScroll={ this.onTableScrolled } id={ constants.SPOTS_TABLE_BODY }>
                        { // Draw table rows (spots).
                            this.props.spots.getAll.data.list.map((spot, index) => {
                                return (
                                    <tr key={ index } className='cursor-pointer' id={ constants.SPOTS + spot.id }>
                                        <td className='cell-w100 center-text' onClick={() => this.onSpotClicked(index) }>{ index + 1 }</td>
                                        <td className='ellipsize-text' onClick={() => this.onSpotClicked(index) }>{ spot.name }</td>
                                        <td className='ellipsize-text' onClick={() => this.onSpotClicked(index) }>{ spot.address }</td>
                                        <td className='cell-w50 cursor-pointer' onClick={() => this.onEditSpotButtonClicked(index) }><img src='../assets/edit.svg' className='center-element' alt='content'/></td>
                                        <td className='cell-w50 cursor-pointer' onClick={() => this.onDeleteSpotButtonClicked(index) }><img src='../assets/delete.svg' className='center-element' alt='content'/></td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>

                <br/> 

                <div className={ this.state.showMoreSpots ? '' : ' invisible-element' }>
                    <Button variant='primary' className='button-large sm-text' onClick={ this.onLoadMoreSpotsButtonClicked }>{ languageService.strings.load_more[languageService.getLanguage()] }</Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div >
                <ContentHeader src={ '../assets/home.svg' } title={ languageService.strings.spots[languageService.getLanguage()] } buttonText={ languageService.strings.new_spot[languageService.getLanguage()] } onAddButtonClicked={ this.onNewSpotButtonClicked } onSearchButtonClicked={ this.getAllSpots } showFilter={ true } />
                
                { /* Content */ }
                { this.props.spots.getAll.data.list === undefined || this.props.spots.getAll.data.list === null || this.props.spots.getAll.data.list.length <= 0 ? <i>{ languageService.strings.spots_not_found[languageService.getLanguage()] }</i> : this.renderContent() }
                
                <SpotModal show={ this.state.showSpotModal } closeModal={ this.onCloseSpotModalClicked } mode={ this.state.mode } title={ this.state.modalTitle } spot={ this.state.selectedSpot } spotPictures={ this.state.spotPictures } categories={ this.state.categories } spotCategories={ this.state.spotCategories } setSpot={ this.setSpot } postState={ this.props.spots.post } patchState={ this.props.spots.patch } getAllSpots={ this.props.getAllSpots } showDashboardToast={ this.props.showDashboardToast } />
            </div>
        );
    }
}

export default Spots;