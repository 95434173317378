import React, { Component } from 'react';

// CSS.
import './LoadingModal.css';

// Components.
import { Spinner, Modal } from 'react-bootstrap';

class LoadingModal extends Component {
    render() {
        return(
            <div>
                <Modal show={ this.props.show } onHide={ this.props.closeModal }>
                    <Modal.Body>
                        <Spinner as='span' animation='border' role='status' aria-hidden='false' className='center-element'/>
                        <p className='no-margin no-padding center-text big-text' id='loading-text'><i>{ this.props.text }</i></p>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}

export default LoadingModal;