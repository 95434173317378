import React, { Component } from 'react';

// CSS.
import './SideMenu.css';

// Components.
import SideMenuHeader from './side-menu-header/SideMenuHeader';
import SideMenuItem from './side-menu-item/SideMenuItem';
import SideMenuFooter from './side-menu-footer/SideMenuFooter';

// Services.
import languageService from '../../utils/language/language';

// Constants.
import constants from '../../utils/constants';

class SideMenu extends Component {
    menuItems = [
        { src : '../assets/home.svg', text : languageService.strings.home[languageService.getLanguage()], id : constants.ITEM_HOME },
        { src : '../assets/location.svg', text : languageService.strings.spots[languageService.getLanguage()], id : constants.ITEM_SPOTS },
        { src : '../assets/users.svg', text : languageService.strings.users[languageService.getLanguage()], id : constants.ITEM_USERS },
        { src : '../assets/notifications.svg', text : languageService.strings.notifications[languageService.getLanguage()], id : constants.ITEM_NOTIFICATIONS },
        { src : '../assets/categories.svg', text : languageService.strings.categories[languageService.getLanguage()], id : constants.ITEM_CATEGORIES },
    ];

    onItemClicked = (index) => {
        this.props.onSideMenuItemClicked(this.menuItems[index].id);
    }

    onLogoutClicked = () => {
        this.props.onLogoutClicked();
    }
    
    render() {
        return (
            <div className='side-menu'>
                <SideMenuHeader />
                
                { // Draw side menu items.
                    this.menuItems.map((item, index) => <SideMenuItem src={ item.src } text={ item.text } key={ index } index={ index } onItemClicked={ this.onItemClicked } />)
                }

                <SideMenuFooter onItemClicked={ this.onLogoutClicked }/>
            </div>
        );
    }
}

export default SideMenu;