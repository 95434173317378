import React, { Component } from 'react';

// CSS.
import './SideMenuFooter.css';

// Services.
import languageService from '../../../utils/language/language';

// Components.
import SideMenuItem from '../side-menu-item/SideMenuItem';

class SideMenuFooter extends Component {
    
    
    render() {
        return (
            <div className='side-menu-footer'>
                <SideMenuItem src='../assets/logout.svg' text={ languageService.strings.log_out[languageService.getLanguage()] } onItemClicked={ this.props.onItemClicked } />
            </div>
        );
    }
}

export default SideMenuFooter;