import constants from './constants';

const emailIsValid = (email) => {
    return email !== undefined && email !== null && constants.RE.test(email.toLowerCase());
}

const passwordIsValid = (password) => {
    return password !== undefined && password !== null && password.length >= constants.PASSWORD_MIN_LENGTH;
}

const nameIsValid = (name) => {
    return name !== undefined && name !== null && name.length > 0;
}

const latitudeIsValid = (latitude) => {
    return latitude !== undefined && latitude !== null && latitude.toString().length > 0;
}

const longitudeIsValid = (longitude) => {
    return longitude !== undefined && longitude !== null && longitude.toString().length > 0;
}

const addressIsValid = (address) => {
    return address !== undefined && address !== null && address.length > 0;
}

const spotTypeIsValid = (type) => {
    return type === constants.SPOT_TYPE_NORMAL || type === constants.SPOT_TYPE_PREMIUM || type === constants.SPOT_TYPE_BUSINESS;
}

const stateIsValid = (state) => {
    return state !== undefined && state !== null && state.length > 0;
}

const descriptionIsValid = (description) => {
    return description !== undefined && description !== null && description.length > 0;
}

export default {
    emailIsValid,
    passwordIsValid,
    nameIsValid,
    latitudeIsValid,
    longitudeIsValid,
    addressIsValid,
    spotTypeIsValid,
    stateIsValid,
    descriptionIsValid,
}