import React, { Component } from 'react';

// CSS.
import './ContentHeader.css';

// Services.
import languageService from '../../utils/language/language';

// Constants.
import constants from '../../utils/constants';

// Components.
import { Button } from 'react-bootstrap';

class ContentHeader extends Component {

    // Method that is called when the user clicksthe search button.
    onSearchButtonClicked = () => {
        const filter = document.getElementById(constants.FILTER);
        this.props.onSearchButtonClicked(0, filter.value);
    }

    render() {
        return (
            <div>
                { /* Content Header */ }
                <div className='row no-margin no-padding'>
                    <div className='col-3 no-margin no-padding'>
                        <img src={ this.props.src } className='content-title-img' alt='content'/>
                        <p className='no-margin no-padding content-title-text'><b>{ this.props.title }</b></p>
                    </div>

                    <div className='col-5 no-margin no-padding'>
                        { this.props.showFilter ? 
                            (
                                <div className='no-margin no-padding input-container'>
                                    <input type='email' placeholder={ languageService.strings.filter[languageService.getLanguage()] } id={ constants.FILTER }/>
                                </div>
                            ) : (
                                ''
                            )
                        }
                    </div>

                    <div className='col-1 no-margin no-padding'>
                        { this.props.showFilter ? 
                            (
                                <div className='no-margin no-padding input-container' id={ constants.SEARCH_BUTTON_CONTAINER }>
                                    <div className='button-large btn-primary center-element' id={ constants.SEARCH_BUTTON } onClick={ this.onSearchButtonClicked }><img src='./assets/search.svg' alt='search' className='center-element' id={ constants.SEARCH_BUTTON_ICON }/></div>
                                </div>
                            ) : (
                                ''
                            )
                        }
                    </div>

                    <div className='col-1 no-margin no-padding'></div>

                    <div className='col-2 no-margin no-padding'>
                        <div className='no-margin no-padding input-container' id={ constants.ADD_BUTTON_CONTAINER }>
                            <Button variant='primary' className='button-large sm-text' onClick={ this.props.onAddButtonClicked }>{ this.props.buttonText }</Button>
                        </div>
                    </div>
                </div> 
                <br />
            </div>
        );
    }
}

export default ContentHeader;