import React, { Component } from 'react';

// Redux.
import { getAllSpots, postSpot, patchSpot, deletePlace, getAllSpotPicturesBySpotId, getAllSpotCategoriesBySpotId } from '../../redux/actions/spots';
import { logout } from '../../redux/actions/user';
import { sendNotificationAll } from '../../redux/actions/notifications';
import { getAllCategories, getCategoryPicture, postCategory, patchCategory, deleteCategory } from '../../redux/actions/categories';
import { connect } from 'react-redux';

// React-Router.
import { withRouter } from 'react-router';

// CSS.
import './Dashboard.css';

// Services.
import languageService from '../../utils/language/language';

// Constants.
import constants from '../../utils/constants';

// Components.
import SideMenu from '../../components/side-menu/SideMenu';
import Home from '../../components/home/Home';
import Spots from '../../components/spots/Spots';
import Users from '../../components/users/Users';
import Notifications from '../../components/notifications/Notifications';
import CustomToast from '../../components/toasts/custom-toast/CustomToast';
import LoadingModal from '../../components/modals/loading/LoadingModal';
import Categories from '../../components/categories/Categories';

const mapStateToProps = state => {
    return {
        login : state.login,
        spots : { getAll : state.getAllSpots, post : state.postSpot, patch : state.patchSpot, delete : state.deletePlace },
        spotPictures : { getAllBySpotId : state.getAllSpotPicturesBySpotId },
        spotCategories : { getAllBySpotId : state.getAllSpotCategoriesBySpotId },
        notifications : { sendAll : state.sendNotificationAll },
        categories : { getAll : state.getAllCategories, categoryPicture : state.getCategoryPicture, post : state.postCategory, patch : state.patchCategory, delete : state.deleteCategory }
    };
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        onGetAllSpots : (lastSpotId, filter, token) => dispatch(getAllSpots({ lastSpotId : lastSpotId, filter : filter, token : token }, languageService.getLanguageString() )),
        onPostSpot : (spot, spotPictures, spotCategories, token) => dispatch(postSpot({ token : token, spot : spot, spotPictures : spotPictures, spotCategories : spotCategories }, languageService.getLanguageString() )), 
        onPatchSpot : (spot, spotPictures, deletedSpotPictures, spotCategories, deletedSpotCategories, token) => dispatch(patchSpot({ token : token, spot : spot, spotPictures : spotPictures, deletedSpotPictures : deletedSpotPictures, spotCategories : spotCategories, deletedSpotCategories : deletedSpotCategories }, languageService.getLanguageString() )), 
        onDeletePlace : (index, spotId, token) => dispatch(deletePlace({ token : token, index : index, spotId : spotId }, languageService.getLanguageString() )), 
        onGetAllSpotPicturesBySpotId : (spotId, lastSpotPictureId, token) => dispatch(getAllSpotPicturesBySpotId({ token : token, lastSpotPictureId : lastSpotPictureId, spotId : spotId }, languageService.getLanguageString() )),
        onGetAllSpotCategoriesBySpotId : (spotId, lastSpotCategoryId, token) => dispatch(getAllSpotCategoriesBySpotId({ token : token, spotId : spotId, lastSpotCategoryId : lastSpotCategoryId }, languageService.getLanguageString() )),
        onLogout : () => dispatch(logout()),
        onSendNotificationAll : (title, message, token) => dispatch(sendNotificationAll({ token : token, notification : { title : title, message : message }}, languageService.getLanguageString() )), 
        onGetAllCategories : (lastCategoryId, token) => dispatch(getAllCategories({ lastCategoryId : lastCategoryId, token : token }, languageService.getLanguageString() )),
        onGetCategoryPicture : (categoryId, token) => dispatch(getCategoryPicture({ categoryId : categoryId, token : token }, languageService.getLanguageString() )),
        onPostCategory : (category, uploadPicture, token) => dispatch(postCategory({ token : token, category : category, uploadPicture : uploadPicture }, languageService.getLanguageString() )), 
        onPatchCategory : (category, uploadPicture, token) => dispatch(patchCategory({ token : token, category : category, uploadPicture : uploadPicture }, languageService.getLanguageString() )), 
        onDeleteCategory : (index, categoryId, token) => dispatch(deleteCategory({ token : token, index : index, categoryId : categoryId }, languageService.getLanguageString() )), 
    };
}

class Dashboard extends Component {
    toastDelay = constants.TOAST_MEDIUM;
    logoutClicked = false;

    constructor() {
        super();

        this.state = {
            selectedSideMenuItemId : constants.ITEM_SPOTS,
            toastMessage : constants.EMPTY_STRING,
            spinnerText : constants.EMPTY_STRING,
        }
    }

    // Method that is called when the user clicks an item of the side menu.
    onSideMenuItemClicked = (id) => {
        this.setState({ selectedSideMenuItemId : id });

        switch(id) {
            case constants.ITEM_HOME: break;
            case constants.ITEM_USERS: break;
            case constants.ITEM_NOTIFICATIONS: break;
            case constants.ITEM_SPOTS: 
                if (this.props.login.loginData.token !== undefined && this.props.login.loginData.token !== null && this.props.login.loginData.token !== constants.EMPTY_STRING) {
                    this.getAllSpots(0, constants.EMPTY_STRING); break;
                }

                break;
            case constants.ITEM_CATEGORIES:
                if (this.props.login.loginData.token !== undefined && this.props.login.loginData.token !== null && this.props.login.loginData.token !== constants.EMPTY_STRING) {
                    this.getAllCategories(0); break;
                }

                break;
            default: break;
        }
    }

    // Method that is called when the user clicks the logout button.
    onLogoutClicked = () => {
        this.logoutClicked = true;
        this.props.onLogout();
    }

    // Method that gets all spots applying a filter.
    getAllSpots = async (lastSpotId, filter) => {
        this.setState({ spinnerText : languageService.strings.loading[languageService.getLanguage()] });
        
        await this.props.onGetAllSpots(lastSpotId, filter, this.props.login.loginData.token);

        this.showToast(this.props.spots.getAll.data.message, constants.TOAST_MEDIUM);
        this.setState({ spinnerText : constants.EMPTY_STRING });
    }

    // Method that posts a spot.
    onPostSpot = (spot, spotPictures, spotCategories) => {
        return this.props.onPostSpot(spot, spotPictures, spotCategories, this.props.login.loginData.token);
    }

    // Method that patches a spot.
    onPatchSpot = (spot, spotPictures, deletedSpotPictures, spotCategories, deletedSpotCategories) => {
        return this.props.onPatchSpot(spot, spotPictures, deletedSpotPictures, spotCategories, deletedSpotCategories, this.props.login.loginData.token);
    }

    // Method that deletes a place.
    onDeletePlace = async (index, spotId) => {
        this.setState({ spinnerText : languageService.strings.deleting_spot[languageService.getLanguage()] });
        
        await this.props.onDeletePlace(index, spotId, this.props.login.loginData.token);

        this.showToast(this.props.spots.delete.data.message, constants.TOAST_MEDIUM);
        this.setState({ spinnerText : constants.EMPTY_STRING });

        this.getAllSpots(this.props.spots.getAll.data.list.length <= 0 ? 0 : this.props.spots.getAll.data.list[this.props.spots.getAll.data.list.length - 1].id, null);
    }

    // Method that gets all pictures related to a spot.
    onGetAllSpotPicturesBySpotId = (spotId, lastSpotPictureId) => {
        return this.props.onGetAllSpotPicturesBySpotId(spotId, lastSpotPictureId, this.props.login.loginData.token);
    }

    // Method that gets all categories related to a spot.
    onGetAllSpotCategoriesBySpotId = (spotId, lastSpotCategoryId) => {
        return this.props.onGetAllSpotCategoriesBySpotId(spotId, lastSpotCategoryId, this.props.login.loginData.token);
    }

    // Method that sends a notification.
    onSendNotificationAll = (title, message) => {
        return this.props.onSendNotificationAll(title, message, this.props.login.loginData.token);
    }

    // Method that gets all categories.
    getAllCategories = async (lastCategoryId) => {
        this.setState({ spinnerText : languageService.strings.loading[languageService.getLanguage()] });
        
        await this.props.onGetAllCategories(lastCategoryId, this.props.login.loginData.token);

        this.showToast(this.props.categories.getAll.data.message, constants.TOAST_MEDIUM);
        this.setState({ spinnerText : constants.EMPTY_STRING });
    }

    // Method that gets the category picture.
    onGetCategoryPicture = (categoryId) => {
        return this.props.onGetCategoryPicture(categoryId, this.props.login.loginData.token);
    }

    // Method that posts a category.
    onPostCategory = (category, uploadPicture) => {
        return this.props.onPostCategory(category, uploadPicture, this.props.login.loginData.token);
    }

    // Method that patches a category.
    onPatchCategory = (category, uploadPicture) => {
        return this.props.onPatchCategory(category, uploadPicture, this.props.login.loginData.token);
    }

    // Method that deletes a category.
    onDeleteCategory = async (index, categoryId) => {
        this.setState({ spinnerText : languageService.strings.deleting_category[languageService.getLanguage()] });
        
        await this.props.onDeleteCategory(index, categoryId, this.props.login.loginData.token);

        this.showToast(this.props.spots.delete.data.message, constants.TOAST_MEDIUM);
        this.setState({ spinnerText : constants.EMPTY_STRING });

        this.getAllSpots(this.props.categories.getAll.data.list.length <= 0 ? 0 : this.props.categories.getAll.data.list[this.props.categories.getAll.data.list.length - 1].id, null);
    }

    // Method that is called to show the toast.
    showToast = (message, delay) => {
        this.toastDelay = delay;
        this.setState({ toastMessage : message });
    }

    // Method that closes the toast.
    closeToast = () => {
        this.setState({ toastMessage : constants.EMPTY_STRING });
    }

    componentDidMount() {
        this.onSideMenuItemClicked(this.state.selectedSideMenuItemId);
    }

    render() {
        if (this.props.login.loginData.token === undefined || this.props.login.loginData.token === null || this.props.login.loginData.token === constants.EMPTY_STRING) {
            if (this.logoutClicked) {
                this.props.history.goBack();
            } else {
                this.props.history.push('/');
            }
            return(
                <div></div>
            );
        }

        this.logoutClicked = false;

        return (
            <div>
                <SideMenu onSideMenuItemClicked={ this.onSideMenuItemClicked } onLogoutClicked={ this.onLogoutClicked }/>
                <div className='dashboard-content'>
                    { this.state.selectedSideMenuItemId === constants.ITEM_HOME ? <Home /> : '' }
                    { this.state.selectedSideMenuItemId === constants.ITEM_SPOTS ? <Spots spots={ this.props.spots } spotPictures={ this.props.spotPictures } spotCategories={ this.props.spotCategories } categories={ this.props.categories } onPostSpot={ this.onPostSpot } onPatchSpot={ this.onPatchSpot } onDeletePlace={ this.onDeletePlace } getAllSpots={ this.getAllSpots } getSpotPictures={ this.onGetAllSpotPicturesBySpotId } getSpotCategories= { this.onGetAllSpotCategoriesBySpotId } getAllCategories={ this.getAllCategories } showDashboardToast={ this.showToast } /> : '' }
                    { this.state.selectedSideMenuItemId === constants.ITEM_USERS ? <Users /> : '' }
                    { this.state.selectedSideMenuItemId === constants.ITEM_NOTIFICATIONS ? <Notifications onSendNotificationAll={ this.onSendNotificationAll } notifications={ this.props.notifications } showDashboardToast={ this.showToast } /> : '' }
                    { this.state.selectedSideMenuItemId === constants.ITEM_CATEGORIES ? <Categories categories={ this.props.categories } onPostCategory={ this.onPostCategory } onPatchCategory={ this.onPatchCategory } onDeleteCategory={ this.onDeleteCategory } getAllCategories={ this.getAllCategories } getCategoryPicture={ this.onGetCategoryPicture } showDashboardToast={ this.showToast } /> : '' }
                </div>

                <CustomToast show={ this.state.toastMessage.length > 0  } close={ this.closeToast } delay={ this.toastDelay } message={ this.state.toastMessage } extraClass={ 'dashbard-toast' }/>

                <LoadingModal show={ this.state.spinnerText.length > 0 } text={ this.state.spinnerText }/>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));