import userActions from '../constants/user';
import spotActions from '../constants/spots';
import constants from '../../utils/constants';
import axios from 'axios';

import languageService from '../../utils/language/language';

export const login = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: userActions.login.ACTION_PENDING });

        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.LOGIN }`, { data : data }, { headers : { 'language' : language } })
            .then(result => {
                const data = result.data.data;

                if (data.role !== constants.ROLE_ADMIN) {
                    dispatch({ type : userActions.login.ACTION_FAILED, payload : languageService.errors.not_authorized[languageService.getLanguage()] });
                    return;
                }

                dispatch({ type : userActions.login.ACTION_SUCCESS, payload : constants.BEARER + data.token });
                resolve(true);
            }).catch(error => {
                dispatch({ type : userActions.login.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

export const resetPassword = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: userActions.resetPassword.ACTION_PENDING });

        axios.patch(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.FORGOT_PASSWORD }`, { data : data }, { headers : { 'language' : language } })
            .then(result => {
                dispatch({ type : userActions.resetPassword.ACTION_SUCCESS, payload : result.data.message });
                resolve(true);
            }).catch(error => {
                dispatch({ type : userActions.resetPassword.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

export const logout = () => (dispatch) => {
    dispatch({ type: spotActions.getAllSpots.ACTION_LOGOUT });
    dispatch({ type: spotActions.postSpot.ACTION_LOGOUT });
    dispatch({ type: spotActions.getSpotPictures.ACTION_LOGOUT });

    dispatch({ type: userActions.resetPassword.ACTION_LOGOUT });
    dispatch({ type: userActions.login.ACTION_LOGOUT });
}