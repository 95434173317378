import categoryActions from '../constants/categories';
import constants from '../../utils/constants';

const getCategoriesDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, previousList : [], list : [], loadMore : true },
};

export const getAllCategoriesReducer = (state = getCategoriesDefaultState, action) => {
    switch(action.type) {
        case categoryActions.getAllCategories.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, previousList : [], list : [] }});
        case categoryActions.getAllCategories.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING, previousList : state.data.list, list : [] }});
        case categoryActions.getAllCategories.ACTION_SUCCESS: 
            let list = action.payload.reload ? [] : state.data.previousList;

            action.payload.categories.forEach(category => list.push(category));

            return Object.assign({}, state, { data : { state : constants.SUCCESS, message : constants.EMPTY_STRING, list : list, loadMore : action.payload.categories !== undefined && action.payload.categories !== null && action.payload.categories.length >= constants.MAX_CATEGORIES_LENGTH }});
        case categoryActions.getAllCategories.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload, list : [], loadMore : true }});
        case categoryActions.getAllCategories.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, previousList : [], list : [] }});
        case categoryActions.getAllCategories.DELETE: 
            state.data.list.splice(action.payload, 1);
            return Object.assign({}, state, { data : { state : constants.SUCCESS, message : constants.EMPTY_STRING, list : state.data.list }});
        default: return state;
    }
}

const getCategoryPictureDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, picture : null },
};

export const getCategoryPictureReducer = (state = getCategoryPictureDefaultState, action) => {
    switch(action.type) {
        case categoryActions.getCategoryPicture.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, picture : null }});
        case categoryActions.getCategoryPicture.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING, picture : null }});
        case categoryActions.getCategoryPicture.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : constants.EMPTY_STRING, picture : action.payload }});
        case categoryActions.getCategoryPicture.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload, picture : null }});
        case categoryActions.getCategoryPicture.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, picture : null }});
        default: return state;
    }
}

const setCategoriesDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, },
};

export const postCategoryReducer = (state = setCategoriesDefaultState, action) => {
    switch(action.type) {
        case categoryActions.postCategory.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case categoryActions.postCategory.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case categoryActions.postCategory.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : action.payload }});
        case categoryActions.postCategory.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload }});
        case categoryActions.postCategory.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        default: return state;
    }
}

export const patchCategoryReducer = (state = setCategoriesDefaultState, action) => {
    switch(action.type) {
        case categoryActions.patchCategory.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case categoryActions.patchCategory.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case categoryActions.patchCategory.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : action.payload }});
        case categoryActions.patchCategory.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload }});
        default: return state;
    }
}

export const deleteCategoryReducer = (state = setCategoriesDefaultState, action) => {
    switch(action.type) {
        case categoryActions.deleteCategory.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case categoryActions.deleteCategory.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case categoryActions.deleteCategory.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : action.payload }});
        case categoryActions.deleteCategory.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload }});
        default: return state;
    }
}