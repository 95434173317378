import categoryActions from '../constants/categories';
import constants from '../../utils/constants';
import axios from 'axios';

import languageService from '../../utils/language/language';

// Get all categories.
export const getAllCategories = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: categoryActions.getAllCategories.ACTION_PENDING });

        axios.get(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.CATEGORIES }/${ constants.ALL }/${ data.lastCategoryId }`, { headers : { 'language' : language, 'authorization' : data.token } })
            .then(result => {
                const resultData = result.data.data;
                dispatch({ type : categoryActions.getAllCategories.ACTION_SUCCESS, payload : { categories : resultData.categories, reload : data.lastCategoryId === undefined || data.lastCategoryId === null || data.lastCategoryId <= 0 }});
                resolve(true);
            }).catch(error => {
                dispatch({ type : categoryActions.getAllCategories.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Get category picture.
export const getCategoryPicture = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: categoryActions.getCategoryPicture.ACTION_PENDING });

        axios.get(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.CATEGORIES }/${ constants.PICTURE }/${ data.categoryId }/${ data.token.replace(constants.BEARER_SPACE, constants.EMPTY_STRING) }/${ language }`, { 'responseType' : 'blob' })
            .then(result => {
                dispatch({ type : categoryActions.getCategoryPicture.ACTION_SUCCESS, payload : result.data });
                resolve(true);
            }).catch(error => {
                dispatch({ type : categoryActions.getCategoryPicture.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Post a category.
export const postCategory = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: categoryActions.postCategory.ACTION_PENDING });

        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.CATEGORIES }`, { data : { category : data.category } }, { headers : { 'language' : language, 'authorization' : data.token }})
            .then(async result => {
                const resultData = result.data;

                let response = await uploadCategoryPicture(resultData.categoryId, data.category.file, data.token, language);

                dispatch({ type : response ? categoryActions.postCategory.ACTION_SUCCESS : categoryActions.postCategory.ACTION_FAILED, payload : response ? resultData.message : languageService.errors.generic_error[languageService.getLanguage()] });
                resolve(response);
            }).catch(error => {
                dispatch({ type : categoryActions.postCategory.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Patch a category.
export const patchCategory = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: categoryActions.postCategory.ACTION_PENDING });

        axios.patch(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.CATEGORIES }/${ data.category.id }`, { data : { category : data.category } }, { headers : { 'language' : language, 'authorization' : data.token }})
            .then(async result => {
                const resultData = result.data;

                if (!data.uploadPicture) {
                    dispatch({ type : categoryActions.patchCategory.ACTION_SUCCESS, payload : resultData.message });
                    resolve(result);
                    return;
                }

                let response = await uploadCategoryPicture(data.category.id, data.category.file, data.token, language);

                dispatch({ type : response ? categoryActions.patchCategory.ACTION_SUCCESS : categoryActions.patchCategory.ACTION_FAILED, payload : response ? resultData.message : languageService.errors.generic_error[languageService.getLanguage()] });
                resolve(response);
            }).catch(error => {
                dispatch({ type : categoryActions.patchCategory.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Method that uploads the cateogry picture.
const uploadCategoryPicture = (categoryId, file, token, language) => {
    let formData = new FormData();
    formData.append(constants.FILE, file);
    
    return new Promise(resolve => {
        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.CATEGORIES }/${ constants.UPLOAD }/${ categoryId }`, formData, { headers : { 'language' : language, 'authorization' : token }})
            .then(result => {
                resolve(true);
            }).catch(error => {
                resolve(false);
            });
    });
}

// Delete a category.
export const deleteCategory = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: categoryActions.deleteCategory.ACTION_PENDING });

        axios.delete(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.CATEGORIES }/${ data.categoryId }`, { headers : { 'language' : language, 'authorization' : data.token } })
            .then(result => {
                dispatch({ type : categoryActions.deleteCategory.ACTION_SUCCESS, payload : result.data.message});
                dispatch({ type : categoryActions.getAllCategories.DELETE, payload : data.index});
                resolve(true);
            }).catch(error => {
                dispatch({ type : categoryActions.deleteCategory.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}