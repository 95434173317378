import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './theme/style.css';
import * as serviceWorker from './serviceWorker';

// Store.
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './redux/reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// React Router.
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store = createStore(reducers, applyMiddleware(thunk));
const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store = { store }>
        <Router>
            <div>
                <Routes />
            </div>
        </Router>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
