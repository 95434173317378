import React, { Component } from 'react';

// CSS.
import './ForgotPasswordModal.css';

// Services.
import languageService from '../../../utils/language/language';
import utils from '../../../utils/utils';

// Components.
import { Button, Modal, Spinner } from 'react-bootstrap';

class ForgotPasswordModal extends Component {
    
    constructor() {
        super();

        this.state = {
            email : '',
            emailIsValid : true,
        }
    }

    // Method that is called when the user changes the email.
    onEmailChanged = (event) => {
        this.setState({ email : event.target.value });
    }

    // Method that is called when the user clicks the reset password button.
    onResetPasswordClicked = () => {
        if (!utils.emailIsValid(this.state.email)) {
            this.setState({ emailIsValid : false });
            return;
        } else {
            this.setState({ emailIsValid : true });
        }

        this.props.onResetPasswordClicked(this.state.email);
    }

    // Method that is called when the user clicks the cancel button.
    onCancelClicked = () => {
        if (!this.props.resettingPassword) {
            this.props.closeModal();
        }
    }

    // Method that resets the email.
    resetEmail = ()  => {
        this.setState({ email : '', emailIsValid : true });
    }

    render() {
        return(
            <div>
                <Modal show={ this.props.show || this.props.resettingPassword } onHide={ this.props.closeModal }>
                    <Modal.Header>
                        <Modal.Title>{ languageService.strings.forgot_password_1[languageService.getLanguage()] }</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        { languageService.strings.forgot_password_modal_text[languageService.getLanguage()] }

                        <div className='no-margin no-padding input-container'>
                            <input type='email' placeholder={ languageService.strings.email[languageService.getLanguage()] } value={ this.state.email } onChange={ this.onEmailChanged }/>
                        </div>

                        {
                            this.state.emailIsValid ? '' : <p className='no-margin no-padding text-color-red sm-text'>{ languageService.errors.invalid_email[languageService.getLanguage()] }</p>
                        }

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant='secondary' onClick={ this.onCancelClicked }>{ languageService.strings.close[languageService.getLanguage()] }</Button>
                        <Button variant='primary' onClick={ this.onResetPasswordClicked }>
                            {
                                this.props.resettingPassword ? <span><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='false' /> </span> : ''
                            }
                            <span>{ this.props.resettingPassword ? languageService.strings.resetting[languageService.getLanguage()] : languageService.strings.reset[languageService.getLanguage()] }</span>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ForgotPasswordModal;