import spotActions from '../constants/spots';
import constants from '../../utils/constants';

const getSpotsDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, previousList : [], list : [], loadMore : true },
};

export const getAllSpotsReducer = (state = getSpotsDefaultState, action) => {
    switch(action.type) {
        case spotActions.getAllSpots.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, previousList : [], list : [] }});
        case spotActions.getAllSpots.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING, previousList : state.data.list, list : [] }});
        case spotActions.getAllSpots.ACTION_SUCCESS: 
            let list = action.payload.reload ? [] : state.data.previousList;

            action.payload.spots.forEach(spot => list.push(spot));

            return Object.assign({}, state, { data : { state : constants.SUCCESS, message : constants.EMPTY_STRING, list : list, loadMore : action.payload.spots !== undefined && action.payload.spots !== null && action.payload.spots.length >= constants.MAX_SPOTS_LENGTH }});
        case spotActions.getAllSpots.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload, list : [], loadMore : true }});
        case spotActions.getAllSpots.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, previousList : [], list : [] }});
        case spotActions.getAllSpots.DELETE: 
            state.data.list.splice(action.payload, 1)
            return Object.assign({}, state, { data : { state : constants.SUCCESS, message : constants.EMPTY_STRING, list : state.data.list }});
        default: return state;
    }
}

const setSpotsDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, },
};

export const postSpotReducer = (state = setSpotsDefaultState, action) => {
    switch(action.type) {
        case spotActions.postSpot.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case spotActions.postSpot.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case spotActions.postSpot.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : action.payload }});
        case spotActions.postSpot.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload }});
        case spotActions.postSpot.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        default: return state;
    }
}

export const patchSpotReducer = (state = setSpotsDefaultState, action) => {
    switch(action.type) {
        case spotActions.patchSpot.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case spotActions.patchSpot.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case spotActions.patchSpot.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : action.payload }});
        case spotActions.patchSpot.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload }});
        default: return state;
    }
}

export const deletePlaceReducer = (state = setSpotsDefaultState, action) => {
    switch(action.type) {
        case spotActions.deletePlace.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case spotActions.deletePlace.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case spotActions.deletePlace.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : action.payload }});
        case spotActions.deletePlace.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload }});
        default: return state;
    }
}

const getSpotPicturesDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, list :[] },
};

export const getAllSpotPicturesBySpotIdReducer = (state = getSpotPicturesDefaultState, action) => {
    switch(action.type) {
        case spotActions.getSpotPictures.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, list : [] }});
        case spotActions.getSpotPictures.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING, list : [] }});
        case spotActions.getSpotPictures.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : constants.EMPTY_STRING, list : action.payload }});
        case spotActions.getSpotPictures.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload, list : [] }});
        case spotActions.getSpotPictures.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, list : [] }});
        default: return state;
    }
}

const getSpotCategoriesDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, list :[] },
};

export const getAllSpotCategoriesBySpotIdReducer = (state = getSpotCategoriesDefaultState, action) => {
    switch(action.type) {
        case spotActions.getSpotcategories.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, list : [] }});
        case spotActions.getSpotcategories.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING, list : [] }});
        case spotActions.getSpotcategories.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : constants.EMPTY_STRING, list : action.payload }});
        case spotActions.getSpotcategories.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload, list : [] }});
        case spotActions.getSpotcategories.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING, list : [] }});
        default: return state;
    }
}