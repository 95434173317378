import React, { Component } from 'react';

// CSS.
import './SpotModal.css';

// Constants.
import constants from '../../../utils/constants';

// Services.
import languageService from '../../../utils/language/language';
import utils from '../../../utils/utils';

// Components.
import { Button, Modal, Spinner } from 'react-bootstrap';
import SpotPictureModal from '../spot-picture/SpotPictureModal';
import CustomToast from '../../toasts/custom-toast/CustomToast';
import LocationSearchInput from '../../google/location-search-input/LocationSearchInput';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

class SpotModal extends Component {
    constructor() {
        super();

        this.state = {
            showPictureModal : false,
            index : null,
            categories : [],
            spot : null,
            spotPictures : [],
            deletedSpotPictures : [],
            spotCategories : [],
            deletedSpotCategories : [],
            showToast : false,
            isSaving : false,
            isAddress : true,
            toastMessage : constants.EMPTY_STRING,
        }
    }

    // Method that is called when the user changes the spot name.
    onNameChanged = (event) => {
        const spot = this.state.spot;
        spot.name = event.target.value;

        this.setState({ spot : spot });
    }

    // Method that is called when the user changes the spot address/coordinates switch.
    onAddressSwitchChanged = (value) => {
        this.setState({ isAddress : value });
    }

    // Method that is called when the user changes the spot address.
    onAddressChanged = (address, latitude, longitude) => {
        const spot = this.state.spot;
        spot.address = address;

        spot.latitude = latitude === null ? spot.latitude : latitude.toString();
        spot.longitude = longitude === null ? spot.longitude : longitude.toString();

        this.setState({ spot : spot });
    }

    // Method that is called when the user changes the spot tags.
    onTagsChanged = (event) => {
        const spot = this.state.spot;
        spot.tags = event.target.value;

        this.setState({ spot : spot });
    }

    // Method that is called when the user changes the latitude.
    onLatitudeChanged = (event) => {
        const spot = this.state.spot;
        spot.latitude = event.target.value;

        this.setState({ spot : spot });
    }

    // Method that is called when the user changes the longitude.
    onLongitudeChanged = (event) => {
        const spot = this.state.spot;
        spot.longitude = event.target.value;

        this.setState({ spot : spot });
    }

    // Method that is called when the user changes the spot type.
    onTypeChanged = (event) => {
        const spot = this.state.spot;
        spot.type = event.target.value;

        this.setState({ spot : spot });
    }

    // Method that is called when the user clicks the map button.
    onMapButtonClicked = () => {
        if (this.state.spot !== undefined && this.state.spot !== null && utils.latitudeIsValid(this.state.spot.latitude) && utils.longitudeIsValid(this.state.spot.longitude)) {
            window.open(`${ constants.MAPS_SEARCH_URL }/${ this.state.spot.latitude },${ this.state.spot.longitude }`, '_blank');
        } else {
            this.setState({ toastMessage : languageService.errors.invalid_address[languageService.getLanguage()], showToast : true });
        }
    }

    // Method that is called when the user clicks the cancel button.
    onCancelClicked = async () => {
        if (!this.state.isSaving) {
            await this.props.closeModal();
            this.setState({ index : null, spot : null, spotPictures : [], isAddress : true });
        }
    }

    // Method that is called when the user clicks the show picture button.
    onShowPictureModalClicked = (index) => {
        this.setState({ showPictureModal : this.props.mode === constants.MODE_INSERT || this.props.mode === constants.MODE_EDIT, index : index });
    }

    // Method that that is called when the user clicks the delete spot picture button..
    onDeleteSpotPictureButtonClicked = (index) => {
        let spotPictures = this.state.spotPictures;
        let deletedSpotPictures = this.state.deletedSpotPictures;

        if (spotPictures[index].id !== undefined && spotPictures[index].id !== null) {
            deletedSpotPictures.push(spotPictures[index].id);
        }

        spotPictures.splice(index, 1);

        this.setState({ spotPictures : spotPictures, deletedSpotPictures : deletedSpotPictures });
    }

    // Method that is called when the user clicks the instagram button.
    onInstagramButtonClicked = (index) => {
        window.open(this.state.spotPictures[index].instagramAccount, '_blank');
    }

    // Method that is called when the user clicks a category.
    onCategoryClicked = (index) => {
        const categories = this.state.categories;
        const spotCategories = this.state.spotCategories;
        const deletedSpotCategories = this.state.deletedSpotCategories;
        
        categories[index].selected = !categories[index].selected;

        let add = categories[index].selected;
        
        // Check if the element needs to be added.
        for (let i = 0; i < spotCategories.length; i++) {
            if (categories[index].id === spotCategories[i].categoryId) {
                if (!categories[index].selected) {
                    if (spotCategories[i].id !== undefined && spotCategories[i].id !== null && spotCategories[i].id > 0) {
                        deletedSpotCategories.push({ id : spotCategories[i].id });
                    }

                    spotCategories.splice(i, 1);
                    i--;
                }
                
                add = false;
                break;
            }
        }
        
        // Add elemet to list.
        if (add) {
            spotCategories.push({ categoryId : categories[index].id, state : constants.STATE_OK });
        }

        // Remove elements from the deleted spot categories list when the element exists in the spot categories list.
        for (let i = 0; i < spotCategories.length; i++) {
            for (let j = 0; j < deletedSpotCategories.length; j++) {
                if (spotCategories[i].categoryId === deletedSpotCategories[j].categoryId) {
                    deletedSpotCategories.splice(j, 1);
                    j--;
                    break;
                }
            }
        }

        this.setState({ categories : categories, spotCategories : spotCategories, deletedSpotCategories : deletedSpotCategories });
    }

    // Method that adds/updates a spot picture.
    setSpotPicture = async (index, spotPicture) => {
        const spotPictures = this.state.spotPictures;

        if (index === null) {
            spotPictures.push({ spotId : this.state.spot.id === undefined || this.state.spot.id === null ? null : this.state.spot.id, url : constants.EMPTY_STRING, file : spotPicture.file, instagramAccount : spotPicture.instagramAccount, state : constants.STATE_OK });
        } else {
            spotPictures[index].file = spotPicture.file !== null ? spotPicture.file : spotPictures[index].file;
            spotPictures[index].instagramAccount = spotPicture.instagramAccount;
        }

        this.setState({ spotPictures : spotPictures });
    }

    // Method that is called when the user clicks the set spot button.
    onSetSpotClicked = async () => {
        if (!utils.nameIsValid(this.state.spot.name)) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_name[languageService.getLanguage()] });
        } else if (!utils.addressIsValid(this.state.spot.address)) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_address[languageService.getLanguage()] });
        } else if (!utils.latitudeIsValid(this.state.spot.latitude)) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_latitude[languageService.getLanguage()] });
        } else if (!utils.longitudeIsValid(this.state.spot.longitude)) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_longitude[languageService.getLanguage()] });
        } else if (!utils.spotTypeIsValid(this.state.spot.type)) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_spot_type[languageService.getLanguage()] });
        } else if (this.state.spotCategories.length <= 0) {
            this.setState({ showToast : true, toastMessage : languageService.errors.not_enough_spot_categories[languageService.getLanguage()] });
        } else if (this.state.spotPictures.length <= 0) {
            this.setState({ showToast : true, toastMessage : languageService.errors.not_enough_spot_pictures[languageService.getLanguage()] });
        } else {
            this.setSpot();
        }
    }

    // Method that sets the spot.
    setSpot = async () => {
        this.setState({ isSaving : true });
        await this.props.setSpot(this.state.spot, this.state.spotPictures, this.state.deletedSpotPictures, this.state.spotCategories, this.state.deletedSpotCategories);

        let data;

        this.setState({ isSaving : false });

        switch(this.props.mode) {
            case constants.MODE_INSERT: data = this.props.postState.data; break;
            case constants.MODE_EDIT: data = this.props.patchState.data; break;
            default: data = null;
        }
        
        if (data !== null) {
            if (data.state === constants.FAILED) {
                this.setState({ toastMessage : data.message, showToast : true })
                return;
            }

            this.onCancelClicked();
            this.props.showDashboardToast(data.message, constants.TOAST_MEDIUM);

            this.props.getAllSpots(0, null);
        } else {
            this.setState({ toastMessage : languageService.errors.generic_error[languageService.getLanguage()], showToast : true });
        }
    }

    // Method that is called when the user cancels the picture modal.
    onCloseSpotPictureModalClicked = () => {
        this.setState({ showPictureModal : false, isAddress : true });
    }

    // Method that closes the toast.
    closeToast = () => {
        this.setState({ showToast : false, toastMessage : constants.EMPTY_STRING });
    }

    componentDidUpdate() {
        if ((this.props.show || this.isSaving) && !this.state.showPictureModal && this.state.spot === null) {
            this.setState( { 
                categories : this.props.categories !== undefined && this.props.categories !== null ? this.props.categories : [],
                spot : this.props.spot !== undefined && this.props.spot !== null ? this.props.spot : { id : null, name : constants.EMPTY_STRING, latitude : constants.EMPTY_STRING, longitude : constants.EMPTY_STRING, address : constants.EMPTY_STRING, type : constants.EMPTY_STRING, tags : constants.EMPTY_STRING, state : constants.STATE_OK }, 
                spotPictures : this.props.spotPictures !== undefined && this.props.spotPictures !== null ? this.props.spotPictures : [],
                deletedSpotPictures : [],
                spotCategories : this.props.spotCategories !== undefined && this.props.spotCategories !== null ? this.props.spotCategories : [],
                deletedSpotCategories : [],
            });
        }
    }

    // Method that renders the spot data.
    renderSpotData = () => {
        return (
            <div>
                <div className='no-margin no-padding input-container'>
                    <input type='text' placeholder={ languageService.strings.name[languageService.getLanguage()] } value={ this.state.spot.name } onChange={ this.onNameChanged } disabled={ this.props.mode === constants.MODE_READ } />
                </div>

                {
                    this.props.mode === constants.MODE_READ ? 
                        '' 
                    :
                        <div className='no-margin no-padding input-container'>
                            <BootstrapSwitchButton checked={ this.state.isAddress } onlabel={ languageService.strings.address[languageService.getLanguage()] } offlabel={ languageService.strings.coordinates[languageService.getLanguage()] } onChange={ this.onAddressSwitchChanged } onstyle='dark' width={ 200 } />
                        </div>
                }

                <div className='no-margin no-padding input-container display-flex' id={ constants.LOCATION_CONTAINER }>
                    <LocationSearchInput address={ this.state.spot.address } latitude={ this.state.spot.latitude } longitude={ this.state.spot.longitude } disabled={ this.props.mode === constants.MODE_READ } isAddress={ this.state.isAddress } onAddressChanged={ this.onAddressChanged }/>
                    <Button variant='primary' className='button-large sm-text' onClick={ this.onMapButtonClicked } id='map-button'>{ languageService.strings.map[languageService.getLanguage()] }</Button>
                </div>
                {
                    this.state.isAddress ? 
                        '' 
                    :
                        <div className='container no-margin no-padding' id='coordinates-container' >
                            <div className='row no-margin no-padding'>
                                <div className='col-6 no-margin no-padding input-container' style={{ paddingRight : '5px', marginTop : '0px', marginBottom : '0px' }}>
                                    <input type='text' placeholder={ languageService.strings.latitude[languageService.getLanguage()] } value={ this.state.spot.latitude } onChange={ this.onLatitudeChanged } disabled={ this.props.mode === constants.MODE_READ || this.state.isAddress } />
                                </div>

                                <div className='col-6 no-margin no-padding input-container' style={{ paddingLeft : '5px', marginTop : '0px', marginBottom : '0px' }}>
                                    <input type='text' placeholder={ languageService.strings.longitude[languageService.getLanguage()] } value={ this.state.spot.longitude } onChange={ this.onLongitudeChanged } disabled={ this.props.mode === constants.MODE_READ || this.state.isAddress } />
                                </div>
                            </div>
                        </div>
                }

                <div className='no-margin no-padding input-container'>
                    <input type='text' placeholder={ languageService.strings.tags_separated[languageService.getLanguage()] } value={ this.state.spot.tags } onChange={ this.onTagsChanged } disabled={ this.props.mode === constants.MODE_READ } />
                </div>

                <div className='no-margin no-padding input-container'>
                    <select id='spot-type' disabled={ this.props.mode === constants.MODE_READ } value={ this.state.spot.type } onChange={ this.onTypeChanged }>
                        <option value={ constants.EMPTY_STRING }>{ languageService.strings.spot_type[languageService.getLanguage()] }</option>
                        <option value={ constants.SPOT_TYPE_NORMAL }>{ languageService.strings.normal[languageService.getLanguage()] }</option>
                        <option value={ constants.SPOT_TYPE_PREMIUM }>{ languageService.strings.premium[languageService.getLanguage()] }</option>
                        <option value={ constants.SPOT_TYPE_BUSINESS }>{ languageService.strings.business[languageService.getLanguage()] }</option>
                    </select>
                </div>
            </div>
        );
    }

    // Method that renders the spot categories.
    renderSpotCategories = () => {
        return (
            <ul className='no-margin no-padding categories-list'>
                { // Draw table rows (spots).
                    this.state.categories.map((category, index) => {
                        return (
                            <li key={ index } className={'categories-list-item cursor-pointer ' + (category.selected ? 'categories-list-item-selected' : 'categories-list-item-not-selected') } onClick={ () => this.props.mode === constants.MODE_READ ? null : this.onCategoryClicked(index) }>{ category.name }</li>
                        );
                    })
                }
            </ul>
        );
    }

    // Method that renders the primary button.
    renderPrimaryButton = () => {
        let loadingText = constants.EMPTY_STRING;
        let normalText = constants.EMPTY_STRING;

        switch(this.props.mode) {
            case constants.MODE_EDIT:
                normalText = languageService.strings.edit_spot[languageService.getLanguage()];
                loadingText = languageService.strings.editting_spot[languageService.getLanguage()];
                break;
            case constants.MODE_INSERT:
                normalText = languageService.strings.create_spot[languageService.getLanguage()];
                loadingText = languageService.strings.creating_spot[languageService.getLanguage()];
                break;
            default: break;
        }

        return (
            <Button variant='primary' onClick={ this.onSetSpotClicked }>
                { this.state.isSaving ? <span><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='false' /> </span> : '' }
                <span>{ this.state.isSaving ? loadingText : normalText }</span>
            </Button>
        );
    }

    render() {
        return(
            <div>
                <Modal show={ (this.props.show || this.state.isSaving) && !this.state.showPictureModal } onHide={ this.onCancelClicked }>
                    <Modal.Header>
                        <Modal.Title>{ this.props.title }</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body className='line'>
                        <p className='no-margin no-padding text'><b>{ languageService.strings.spot_data[languageService.getLanguage()] }</b></p>
                        { this.state.spot === null ? '' : this.renderSpotData() }
                    </Modal.Body>

                    <Modal.Body className='line'>
                        <p className='no-margin no-padding text'><b>{ languageService.strings.spot_categories[languageService.getLanguage()] }</b></p>
                        { this.state.categories === null ? '' : this.renderSpotCategories() }
                    </Modal.Body>

                    <Modal.Body>
                        <p className='no-margin no-padding text'><b>{ languageService.strings.pictures[languageService.getLanguage()] } ({ this.state.spotPictures === undefined || this.state.spotPictures === null ? 0 : this.state.spotPictures.length })</b></p>

                        <div className='no-margin no-padding input-container spot-pictures'>
                            { this.props.mode === constants.MODE_READ ? '' : <div className='spot-picture-container cursor-pointer' onClick={ () => this.onShowPictureModalClicked(null) }><div className='spot-picture-container-element-container'><img src='../assets/add.svg' alt='addPicture' className='center-element' id='add-spot-picture'/></div></div> }
                            {  
                                this.state.spotPictures.map((spotPicture, index) => {
                                    return (
                                        <div className='spot-picture-container' key={ index }>
                                            <div className='spot-picture-container-element-container'>
                                                <img src={ spotPicture.file !== undefined && spotPicture.file !== null ? URL.createObjectURL(spotPicture.file) : spotPicture.url !== undefined && spotPicture.url !== null ? URL.createObjectURL(spotPicture.url) : '../assets/img_not_found.jpg' } alt={ `${spotPicture}${index}` } className={'spot-picture-container-element center-element ' + (this.props.mode === constants.MODE_READ ? '' : 'cursor-pointer')} onClick={ () => this.onShowPictureModalClicked(index) }/>
                                                { this.props.mode === constants.MODE_READ ? '' : <img src='../assets/delete.svg' alt='delete' className='delete-spot-picture float-right cursor-pointer' onClick={ () => this.onDeleteSpotPictureButtonClicked(index) }/> }
                                                <img src='../assets/instagram.svg' alt='instagram' className={'instagram-spot-picture float-left cursor-pointer ' + ( spotPicture.instagramAccount === null || spotPicture.instagramAccount === constants.EMPTY_STRING ? 'invisible-element' : '' )} onClick={ () => this.onInstagramButtonClicked(index) }/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant='secondary' onClick={ this.onCancelClicked }>{ languageService.strings.close[languageService.getLanguage()] }</Button>
                        { this.props.mode === constants.MODE_READ ? '' : this.renderPrimaryButton() }
                    </Modal.Footer>
                </Modal>

                <SpotPictureModal show={ this.state.showPictureModal } closeModal={ this.onCloseSpotPictureModalClicked } setSpotPicture={ this.setSpotPicture } index={ this.state.index } spotPicture={ this.state.index === null ? { instagramAccount : constants.EMPTY_STRING } : this.state.spotPictures[this.state.index] }/>
                <CustomToast show={ this.state.showToast } close={ this.closeToast } delay={ constants.TOAST_MEDIUM } message={ this.state.toastMessage } extraClass={ 'no-left' }/>
            </div>
        );
    }

}

export default SpotModal;