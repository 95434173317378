const spotActions = {
    getAllSpots : {
        ACTION_INIT : 'action_get_all_spots_init',
        ACTION_PENDING : 'action_get_all_spots_pending',
        ACTION_SUCCESS : 'action_get_all_spots_success',
        ACTION_FAILED : 'action_get_all_spots_failed',
        ACTION_LOGOUT : 'action_get_all_spots_logout',
        DELETE : 'action_get_all_spots_delete',
    },
    postSpot : {
        ACTION_INIT : 'action_post_spot_init',
        ACTION_PENDING : 'action_post_spot_pending',
        ACTION_SUCCESS : 'action_post_spot_success',
        ACTION_FAILED : 'action_post_spot_failed',
        ACTION_LOGOUT : 'action_set_spot_logout',
    },
    patchSpot : {
        ACTION_INIT : 'action_patch_spot_init',
        ACTION_PENDING : 'action_patch_spot_pending',
        ACTION_SUCCESS : 'action_patch_spot_success',
        ACTION_FAILED : 'action_patch_spot_failed',
    },
    deletePlace : {
        ACTION_INIT : 'action_delete_place_init',
        ACTION_PENDING : 'action_delete_place_pending',
        ACTION_SUCCESS : 'action_delete_place_success',
        ACTION_FAILED : 'action_delete_place_failed',
    },
    getSpotPictures : {
        ACTION_INIT : 'action_get_spot_pictures_init',
        ACTION_PENDING : 'action_get_spot_pictures_pending',
        ACTION_SUCCESS : 'action_get_spot_pictures_success',
        ACTION_FAILED : 'action_get_spot_pictures_failed',
        ACTION_LOGOUT : 'action_get_spot_pictures_logout',
    },
    getSpotcategories : {
        ACTION_INIT : 'action_get_spot_categories_init',
        ACTION_PENDING : 'action_get_spot_categories_pending',
        ACTION_SUCCESS : 'action_get_spot_categories_success',
        ACTION_FAILED : 'action_get_spot_categories_failed',
        ACTION_LOGOUT : 'action_get_spot_categories_logout',
    },
}

export default spotActions;