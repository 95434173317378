import React, { Component } from 'react';

// CSS.
import './LocationSearchInput.css';

// Services.
import languageService from '../../../utils/language/language';
import axios from 'axios';

// Constants.
import constants from '../../../utils/constants';

// Components.
import { Spinner } from 'react-bootstrap';

class LocationSearchInput extends Component {
    timeout = null;
    geocoder = null;

    constructor(props) {
        super(props);

        this.state = { 
            address: props.address,
            latitude : constants.EMPTY_STRING,
            longitude : constants.EMPTY_STRING,
            isLoading : false,
            addressList : [],
        };
    }

    // Method that handles the text change.
    handleChange = (event) => {
        const address = event.target.value;

        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
        
        this.setState({ address : address, latitude : this.props.isAddress ? constants.EMPTY_STRING : this.state.latitude, longitude : this.props.isAddress ? constants.EMPTY_STRING : this.state.longitude, addressList : [] });
        this.props.onAddressChanged(this.state.address, this.props.isAddress ? this.state.latitude : null, this.props.isAddress ? this.state.longitude : null);
        
        if (address === undefined || address === null || address.length < constants.MIN_ADDRESS_LENGTH) {
            return;
        }

        this.timeout = setTimeout(() => {
            this.setState({ isLoading : true });
            axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${ address }&key=${ constants.API_KEY_GOOGLE_MAPS }`)
                .then(result => {
                    this.setState({ isLoading : false, addressList : result.data.results });
                }).catch(error => {
                    this.setState({ isLoading : false });
                });
        }, constants.MAPS_REQUEST_TIME);
    }

    // Method that handles the address selection.
    handleSelect = async (index) => {
        const address = this.state.addressList[index];
        await this.setState({ address : address.formatted_address, latitude : address.geometry.location.lat, longitude : address.geometry.location.lng, addressList : [] });
        this.props.onAddressChanged(this.state.address, this.state.latitude, this.state.longitude);
    };

    // Method that closes the hint address container.
    closeHintContainer = () => {
        this.setState({ addressList : [] });
        this.props.onAddressChanged(this.state.address, this.props.latitude, this.props.longitude);
    }

    render() {
        return (
            <div className='no-margin no-padding location-search-input-container'>
                <input type='text' placeholder={ languageService.strings.address[languageService.getLanguage()] } value={ this.state.address } onChange={ this.handleChange } disabled={ this.props.disabled } />
                { this.state.isLoading ? <span><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='false' /> </span> : '' }
                <div className={ this.state.addressList === undefined || this.state.addressList === null || this.state.addressList.length <= 0 ? 'invisible-element' : 'location-search-results' }>
                    <div className='container no-margin no-padding' id='hint-container'>
                        <div className='row no-margin no-padding'>
                            <div className='col-9 no-margin no-padding'>
                                <p className='no-margin no-padding sm-text'><i>{ languageService.strings.search_results[languageService.getLanguage()] } ({ this.state.addressList.length }):</i></p>
                            </div>
                            {
                                this.props.isAddress ?
                                    ''
                                :
                                    <div className='col-3 no-margin no-padding'>
                                        <p className='no-margin no-padding right-text sm-text text-color-red cursor-pointer' onClick={ () => this.closeHintContainer() }><b>{ languageService.strings.close[languageService.getLanguage()] }</b></p>
                                    </div>
                            }
                        </div>
                    </div>
                    {   
                        this.state.addressList.map((address, index) => {
                            return (
                                <div className='address-element-container' key={ index } id={ index } onClick={ () => this.handleSelect(index) }>
                                    <p className='no-margin no-padding sm-text address-element-text'><b>{ address.formatted_address }</b></p>
                                    <hr className={ 'no-margin no-padding' + (index === this.state.addressList.length - 1 ? ' invisible-element' : '') }/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default LocationSearchInput;