import notificationsActions from '../constants/notifications';
import constants from '../../utils/constants';
import axios from 'axios';

import languageService from '../../utils/language/language';

export const sendNotificationAll = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: notificationsActions.sendAll.ACTION_PENDING });

        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.NOTIFICATIONS }/${ constants.ALL }`, { data : { notification : data.notification } }, { headers : { 'language' : language, 'authorization' : data.token }})
            .then(async result => {
                const resultData = result.data;
                let response = true;

                dispatch({ type : response ? notificationsActions.sendAll.ACTION_SUCCESS : notificationsActions.sendAll.ACTION_FAILED, payload : response ? resultData.message : languageService.errors.generic_error[languageService.getLanguage()] });
                resolve(response);
            }).catch(error => {
                dispatch({ type : notificationsActions.sendAll.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}