import { loginReducer, resetPasswordReducer } from './user';
import { getAllSpotsReducer, postSpotReducer, getAllSpotPicturesBySpotIdReducer, patchSpotReducer, deletePlaceReducer, getAllSpotCategoriesBySpotIdReducer } from './spots';
import { sendNotificationAllReducer } from './notifications';
import { getAllCategoriesReducer, getCategoryPictureReducer, postCategoryReducer, patchCategoryReducer, deleteCategoryReducer } from './categories';

import { combineReducers } from 'redux';

const reducers = combineReducers({
    // Users.
    login : loginReducer,
    resetPassword : resetPasswordReducer,

    // Spots.
    getAllSpots : getAllSpotsReducer,
    postSpot : postSpotReducer,
    getAllSpotPicturesBySpotId : getAllSpotPicturesBySpotIdReducer,
    getAllSpotCategoriesBySpotId : getAllSpotCategoriesBySpotIdReducer,
    patchSpot : patchSpotReducer,
    deletePlace : deletePlaceReducer,
    sendNotificationAll : sendNotificationAllReducer,

    // Categories.
    getAllCategories : getAllCategoriesReducer,
    getCategoryPicture : getCategoryPictureReducer,
    postCategory : postCategoryReducer,
    patchCategory : patchCategoryReducer,
    deleteCategory : deleteCategoryReducer,
});

export default reducers;