import React, { Component } from 'react';

// CSS.
import './Notifications.css';

// Services.
import languageService from '../../utils/language/language';

// Constants.
import constants from '../../utils/constants';

// Components.
import { Spinner, Button } from 'react-bootstrap';
import CustomToast from '../toasts/custom-toast/CustomToast';

class Notifications extends Component {
    toastDelay = constants.TOAST_MEDIUM;
    
    constructor() {
        super();

        this.state = {
            title : constants.EMPTY_STRING,
            message : constants.EMPTY_STRING,
            isSending : false,
        }
    }

    // Method that is called when the user changes the title.
    onTitleChanged = (event) => {
        this.setState({ title : event.target.value })
    }

    // Method that is called when the user changes the message.
    onMessageChanged = (event) => {
        this.setState({ message : event.target.value })
    }
    
    // Method that is called when the user clicks the send notification button.
    onSendNotificationClicked = async () => {
        if (this.state.title.length <= 0) {
            this.props.showDashboardToast(languageService.errors.invalid_title[languageService.getLanguage()], this.toastDelay);
            return;
        } else if (this.state.message.length <= 0) {
            this.props.showDashboardToast(languageService.errors.invalid_message[languageService.getLanguage()], this.toastDelay);
            return;
        }
        
        this.setState({ isSending : true });

        await this.props.onSendNotificationAll(this.state.title, this.state.message);

        const data = this.props.notifications.sendAll.data;

        if (data.state === constants.SUCCESS) {
            this.setState({ title : constants.EMPTY_STRING, message : constants.EMPTY_STRING });
        }

        this.props.showDashboardToast(data.message, this.toastDelay);
        this.setState({ isSending : false });
    }

    render() {
        return (
            <div className=''>
                <div>
                    <img src='../assets/notifications.svg' className='content-title-img' alt='content'/>
                    <p className='no-margin no-padding content-title-text'><b>{ languageService.strings.notifications[languageService.getLanguage()] }</b></p>
                </div>
                <br />

                <p className='no-margin no-padding'>{ languageService.strings.send_notifications_text[languageService.getLanguage()] }</p>

                <div className='no-margin no-padding input-container'>
                    <input type='text' placeholder={ languageService.strings.title[languageService.getLanguage()] } value={ this.state.title } onChange={ this.onTitleChanged } />
                </div>

                <div className='no-margin no-padding input-container'>
                    <input type='text' placeholder={ languageService.strings.message[languageService.getLanguage()] } value={ this.state.message } onChange={ this.onMessageChanged } />
                </div>

                <Button variant='primary' onClick={ this.onSendNotificationClicked }>
                    { this.state.isSending ? <span><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='false' /> </span> : '' }
                    <span>{ this.state.isSending ? languageService.strings.sending[languageService.getLanguage()]  : languageService.strings.send[languageService.getLanguage()] }</span>
                </Button>
            </div>
        );
    }
}

export default Notifications;