const errors = {
    invalid_address : [
        'El formato de la dirección no es válido', // sp
        'The address format is not valid', // en
    ],
    invalid_category_picture : [
        'Es necesario que la categoría tenga una imagen', // sp
        'The category needs, at least, one picture', // en
    ],
    invalid_description : [
        'El formato de la descripción no es válido', // sp
        'The description format is not valid', // en
    ],
    invalid_email : [
        'El formato del email no es válido', // sp
        'The email format is not valid', // en
    ],
    invalid_fields : [
        'Campos Inválidos', // sp
        'Invalid Fields', // en
    ],
    invalid_file : [
        'El archivo es inválido', // sp
        'The file is not valid', // en
    ],
    invalid_latitude : [
        'El formato de la latitud de la dirección no es válido', // sp
        'The latitude address format is not valid', // en
    ],
    invalid_longitude : [
        'El formato de la longitud de la dirección no es válido', // sp
        'The longitude address format is not valid', // en
    ],
    invalid_message: [
        'El formato del mensaje no es válido', // sp
        'The message format is not valid', // en
    ],
    invalid_name : [
        'El formato del nombre no es válido', // sp
        'The name format is not valid', // en
    ],
    invalid_password : [
        'El formato de la contraseña no es válido', // sp
        'The password format is not valid', // en
    ],
    invalid_spot_type : [
        'El tipo del spot solamente puede ser "normal", "premium" o "negocio"', // sp
        'The spot type can only be "normal", "premium" or "business"', // en
    ],
    invalid_title : [
        'El formato del título no es válido', // sp
        'The title format is not valid', // en
    ],
    generic_error : [
        'Se ha producido un error interno en el servidor', // sp
        'An intern server error occurred', // en
    ],
    not_authorized : [
        '¡No estás autorizado!', // sp
        'You are not authorized!', // en
    ],
    not_enough_spot_categories : [
        'Es necesario que haya al menos 1 categoría por spot', // sp
        'At least 1 category needs to be added to the spot', // en
    ],
    not_enough_spot_pictures : [
        'Es necesario que haya al menos 1 imagen por spot', // sp
        'At least 1 picture needs to be added to the spot', // en
    ],
}

export default errors;