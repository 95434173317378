const strings = {
    add : [
        'Añadir', // sp
        'Add', // en 
    ],
    address : [
        'Dirección', // sp
        'Address', // en 
    ],
    app_name : [
        'Spotshot', // sp
        'Spotshot', // en 
    ],
    business : [
        'Negocio', // sp
        'Business', // en 
    ],
    categories : [
        'Categorías', // sp
        'Categories', // en
    ],
    categories_not_found : [
        'No se han encontrado categorías.', // sp
        'No categories have been found.', // en
    ],
    category_data : [
        'Datos de la Categoría', // sp
        'Category Data', // en
    ],
    close : [
        'Cerrar', // sp
        'Close', // en
    ],
    coordinates : [
        'Coordenadas', // sp
        'Coordinates', // en
    ],
    copyright : [
        'Spotshot. Todos los derechos reservados.', // sp
        'Spotshot. All rights reserved.', // en
    ],
    create_category : [
        'Crear Categoría', // sp
        'Create Category', // en
    ],
    create_spot : [
        'Crear Spot', // sp
        'Create Spot', // en
    ],
    creating_category : [
        'Creando Categoría', // sp
        'Creating Category', // en
    ],
    creating_spot : [
        'Creando Spot', // sp
        'Creating Spot', // en
    ],
    deleting_category : [
        'Eliminando Categoría', // sp
        'Deleting Category', // en
    ],
    deleting_spot : [
        'Eliminando Spot', // sp
        'Deleting Spot', // en
    ],
    description : [
        'Descripción', // sp
        'Description', // en
    ],
    edit : [
        'Editar', // sp
        'Edit', // en
    ],
    edit_category : [
        'Editar Categoría', // sp
        'Edit Category', // en
    ],
    edit_spot : [
        'Editar Spot', // sp
        'Edit Spot', // en
    ],
    editting_category : [
        'Editando Categoría', // sp
        'Editting Category', // en
    ],
    editting_spot : [
        'Editando Spot', // sp
        'Editting Spot', // en
    ],
    email : [
        'Email', // sp
        'Email', // en
    ],
    filter : [
        'Filtrar', // sp
        'Filter', // en
    ],
    forgot_password_1 : [
        '¿Olvidaste tu contraseña?', // sp
        'Lost your password?', // en
    ],
    forgot_password_2 : [
        'Pincha aquí', // sp
        'Click here', // en
    ],
    forgot_password_modal_text : [
        'Introduce tu email para resetear la contraseña. Te enviaremos un email con instrucciones de cómo proceder.', // sp
        'Enter your email to reset the password. We\'ll email you with instructions of how to proceed.', // en
    ],
    home : [
        'Inicio', // sp
        'Home', // en
    ],
    instagram_account : [
        'Cuenta de Instagram', // sp
        'Instagram Account', // en
    ],
    latitude : [
        'Latitud', // sp
        'Latitude', // en
    ],
    load_more : [
        'Cargar Más', // sp
        'Load More', // en
    ],
    loading : [
        'Cargando...', // sp
        'Loading...', // en
    ],
    login : [
        'Iniciar Sesión', // sp
        'Login', // en
    ],
    logging : [
        'Iniciando Sesión', // sp
        'Logging', // en
    ],
    log_out : [
        'Cerrar Sesión', // sp
        'Log Out', // en
    ],
    longitude : [
        'Longitud', // sp
        'Longitude', // en
    ],
    map : [
        'Mapa', // sp
        'Map', // en 
    ],
    message : [
        'Mensaje', // sp
        'Message', // en 
    ],
    name : [
        'Nombre', // sp
        'Name', // en 
    ],
    new_category : [
        'Categoría Nueva', // sp
        'New Category', // en 
    ],
    new_spot : [
        'Spot Nuevo', // sp
        'New Spot', // en 
    ],
    normal : [
        'Normal', // sp
        'Normal', // en 
    ],
    notifications : [
        'Notificaciones', // sp
        'Notifications', // en 
    ],
    password : [
        'Contraseña', // sp
        'Password', // en
    ],
    picture : [
        'Imagen', // sp
        'Picture', // en
    ],
    pictures : [
        'Imágenes', // sp
        'Pictures', // en
    ],
    premium : [
        'Premium', // sp
        'Premium', // en
    ],
    reload : [
        'Recargar', // sp
        'Reload', // en 
    ],
    reset : [
        'Resetear', // sp
        'Reset', // en
    ],
    resetting : [
        'Reseteando', // sp
        'Resetting', // en
    ],
    search_results : [
        'Resultados de la Búsqueda', // sp
        'Search Results', // en 
    ],
    send : [
        'Enviar', // sp
        'Send', // en 
    ],
    sending : [
        'Enviando...', // sp
        'Sending...', // en 
    ],
    send_notifications_text : [
        'Envía una notificación PUSH a todos los usuarios de la aplicación que tengan habilitada la recepción de notificaciones.', // sp
        'Send a PUSH notifications to all app users who have the notification reception enabled.', // en 
    ],
    spot_categories : [
        'Categorías del Spot', // sp
        'Spot Categories', // en
    ],
    spot_data : [
        'Datos del Spot', // sp
        'Spot Data', // en
    ],
    spots : [
        'Spots', // sp
        'Spots', // en
    ],
    spots_not_found : [
        'No se han encontrado spots.', // sp
        'No spots have been found.', // en
    ],
    spot_picture : [
        'Imagen del Spot', // sp
        'Spot Picture', // en
    ],
    spot_type : [
        'Tipo de Spot', // sp
        'Spot Type', // en
    ],
    tags_separated : [
        'Tags separados por ";"', // sp
        'Tags separated by ";"', // en
    ],
    title : [
        'Título', // sp
        'Title', // en
    ],
    users : [
        'Usuarios', // sp
        'Users', // en
    ],
    view_category : [
        'Ver Categoría', // sp
        'View Category', // en
    ],
    view_spot : [
        'Ver Spot', // sp
        'View Spot', // en
    ],
}

export default strings;