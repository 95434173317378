import React, { Component } from 'react';

// CSS.
import './LoginForm.css';

// Services.
import languageService from '../../utils/language/language';

// Constants.
import constants from '../../utils/constants';

// Components.
import { Button, Spinner } from 'react-bootstrap';

class  LoginForm extends Component {

    onLoginButtonClicked = () => {
        if (!this.props.logging) {
            this.props.onLoginButtonClicked();
        }
    }

    render () {
        return (
            <form className='no-margin no-padding center-element login-container'>
                <img src='../assets/logo.png' className='center-element' id='login-img' alt='logo'/>

                <div className='no-margin no-padding input-container'>
                    <input type='email' placeholder={ languageService.strings.email[languageService.getLanguage()] } id={ constants.EMAIL }/>
                </div>

                <div className='no-margin no-padding input-container'>
                    <input type='password' placeholder={ languageService.strings.password[languageService.getLanguage()] } id={ constants.PASSWORD }/>
                </div>

                <div className='no-margin no-padding input-container'>
                    <Button variant='primary' className='button-large' onClick={ this.onLoginButtonClicked }>
                        {
                            this.props.logging ? <span><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='false' /> </span> : ''
                        }
                        <span>{ this.props.logging ? languageService.strings.logging[languageService.getLanguage()] : languageService.strings.login[languageService.getLanguage()] }</span>
                    </Button>
                </div>

                <div className='no-margin no-padding input-container'>
                    <p className='no-margin no-padding center-text text' id='lost-password-text'>{ languageService.strings.forgot_password_1[languageService.getLanguage()] } <span id='lost-password-span' onClick={ this.props.onForgotPasswordClicked }>{ languageService.strings.forgot_password_2[languageService.getLanguage()] }.</span></p>
                </div>
            </form>
        );
    }
}

export default LoginForm;