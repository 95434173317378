import React, { Component } from 'react';

// Redux.
import { login, resetPassword } from '../../redux/actions/user';
import { connect } from 'react-redux';

// React-Router.
import { withRouter } from 'react-router';

// CSS.
import './Login.css';

// Services.
import languageService from '../../utils/language/language';
import utils from '../../utils/utils';

// Constants.
import constants from '../../utils/constants';

// Components
import Footer from '../../components/footer/Footer';
import LoginForm from '../../components/login-form/LoginForm';
import ForgotPasswordModal from '../../components/modals/forgot-password/ForgotPasswordModal';
import CustomToast from '../../components/toasts/custom-toast/CustomToast';

const mapStateToProps = state => {
    return {
        resetPassword : state.resetPassword,
        login : state.login,
    };
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      onResetPassword: (email) => dispatch(resetPassword({ email : email }, languageService.getLanguageString() )),
      onLogin: (email, password) => dispatch(login({ email : email, password : password }, languageService.getLanguageString() )),
    };
}

class Login extends Component {
    toastDelay = constants.TOAST_MEDIUM;

    constructor() {
        super();

        this.state = {
            showForgotPasswordModal : false,
            resettingPassword : false,
            logging : false,
            toastMessage : constants.EMPTY_STRING,
        }

        this.forgotPasswordModal = React.createRef();
    }

    // Method that is called when the user clicks the log in button.
    onLoginButtonClicked = () => {
        const email = document.getElementById(constants.EMAIL);
        const password = document.getElementById(constants.PASSWORD);

        this.toastDelay = constants.TOAST_SHORT;
        
        if (!utils.emailIsValid(email.value)) {
            this.setState({ toastMessage : languageService.errors.invalid_email[languageService.getLanguage()] });
            return;
        } 
        
        if (!utils.passwordIsValid(password.value)) {
            this.setState({ toastMessage : languageService.errors.invalid_password[languageService.getLanguage()] });
            return;
        }

        this.login(email.value, password.value);
    }

    // Method that logs the user in.
    login = async (email, password) => {
        this.setState({ logging : true });

        await this.props.onLogin(email, password);
        
        this.setState({ logging : false });

        this.toastDelay = constants.TOAST_MEDIUM;
        this.setState({ toastMessage : this.props.login.loginData.message });
        
        if (this.props.login.loginData.message.length <= 0 && this.props.login.loginData.token.length > 0) {
            this.props.history.push(constants.ROUTE_DASHBOARD);
        }
    }

    // Method that is called when the user clicks the forgot password button.
    onForgotPasswordClicked = () => {
        this.setState({ showForgotPasswordModal : true })
    }

    // Method that closes the forgot password modal.
    onCloseForgotPasswordModalClicked = () => {
        this.setState({ showForgotPasswordModal : false })
        this.forgotPasswordModal.current.resetEmail();
    }

    // Method that closes the error modal.
    onCloseErrorModalClicked = () => {
        this.setState({ showErrorModal : false, modalTitle : constants.EMPTY_STRING, modalMessage : constants.EMPTY_STRING })
    }

    // Method that is called to reset the password.
    resetPassword = async (email) => {
        this.setState({ resettingPassword : true });

        await this.props.onResetPassword(email);

        this.setState({ showForgotPasswordModal : this.props.resetPassword.resetData.state !== constants.SUCCESS, resettingPassword : false });

        if (this.props.resetPassword.resetData.state === constants.SUCCESS) {
            this.forgotPasswordModal.current.resetEmail();
        }

        this.toastDelay = constants.TOAST_MEDIUM;
        this.setState({ toastMessage : this.props.resetPassword.resetData.message });
    }

    // Method that closes the toast.
    closeToast = () => {
        this.setState({ toastMessage : constants.EMPTY_STRING });
    }

    render() {
        return (
            <div>
                <div className='no-margin no-padding'>
                    <LoginForm onLoginButtonClicked={ this.onLoginButtonClicked } onForgotPasswordClicked={ this.onForgotPasswordClicked } logging={ this.state.logging }/>
                    <CustomToast show={ this.state.toastMessage.length > 0  } close={ this.closeToast } delay={ this.toastDelay } message={ this.state.toastMessage }/>
                    <Footer />
                    <ForgotPasswordModal ref={ this.forgotPasswordModal } show={ this.state.showForgotPasswordModal } closeModal={ this.onCloseForgotPasswordModalClicked } resettingPassword={ this.props.resetPassword.resetData.state === constants.PENDING } onResetPasswordClicked={ this.resetPassword }/>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));