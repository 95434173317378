import React, { Component } from 'react';

// CSS
import './CustomToast.css';

// Component.
import { Toast, Row, Col } from 'react-bootstrap';

class CustomToast extends Component {
    
    onCloseToast = () => {
        this.props.close();
    }

    render() {
        return (
            <div className={'toast-container center-element' + (this.props.extraClass !== undefined && this.props.extraClass !== null ? ` ${ this.props.extraClass }` : '')}>
                <div className=''>
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            <Toast onClose={ this.props.close } show={ this.props.show } delay={ this.props.delay } autohide>
                                <Toast.Body className='center-text'>{ this.props.message }</Toast.Body>
                            </Toast>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default CustomToast;