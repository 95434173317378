import React, { Component } from 'react';

// CSS.
import './SideMenuHeader.css';

// Services.
import languageService from '../../../utils/language/language';

class SideMenuHeader extends Component {
    
    
    render() {
        return (
            <div className='side-menu-header'>
                <p className='center-text' id='side-menu-header-title'><b>{ languageService.strings.app_name[languageService.getLanguage()] }</b></p>
                <img src='../assets/logo.png' className='center-element' id='side-menu-header-logo' alt='logo'/>
            </div>
        );
    }
}

export default SideMenuHeader;