import React, { Component } from 'react';

// CSS.
import './Home.css';

// Services.
import languageService from '../../utils/language/language';

class Home extends Component {
    
    render() {
        return (
            <div className=''>
                <div>
                    <img src='../assets/home.svg' className='content-title-img' alt='content'/>
                    <p className='no-margin no-padding content-title-text'><b>{ languageService.strings.home[languageService.getLanguage()] }</b></p>
                </div>
                <br />
                Página en construcción...
            </div>
        );
    }
}

export default Home;