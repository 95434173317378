import spotActions from '../constants/spots';
import constants from '../../utils/constants';
import axios from 'axios';

import languageService from '../../utils/language/language';

// Get all spots.
export const getAllSpots = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: spotActions.getAllSpots.ACTION_PENDING });
        axios.get(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOTS }/${ constants.FILTER }/${ data.filter === undefined || data.filter === constants.EMPTY_STRING ? null : data.filter }/${ data.lastSpotId }`, { headers : { 'language' : language, 'authorization' : data.token } })
            .then(result => {
                const resultData = result.data.data;
                dispatch({ type : spotActions.getAllSpots.ACTION_SUCCESS, payload : { spots : resultData.spots, reload : data.lastSpotId === undefined || data.lastSpotId === null || data.lastSpotId <= 0 }});
                resolve(true);
            }).catch(error => {
                dispatch({ type : spotActions.getAllSpots.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Post a spot.
export const postSpot = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: spotActions.postSpot.ACTION_PENDING });

        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOTS }`, { data : { spot : data.spot } }, { headers : { 'language' : language, 'authorization' : data.token }})
            .then(async result => {
                const resultData = result.data;
                let response = true;
                
                // Post spot pictures.
                for (let i = 0; i < data.spotPictures.length; i++) {
                    const spotPicture = data.spotPictures[i];

                    response = await postSpotPicture({ spotId : resultData.spotId, instagramAccount : spotPicture.instagramAccount, state : spotPicture.state }, spotPicture.file, data.token, language);

                    if (!response) {
                        break;
                    }
                }

                if (!response) {
                    dispatch({ type : spotActions.postSpot.ACTION_FAILED, payload : languageService.errors.generic_error[languageService.getLanguage()] });
                    return;
                }

                // Post spot categories.
                for (let i = 0; i < data.spotCategories.length; i++) {
                    const spotCategory = data.spotCategories[i];
                    response = await postSpotCategory({ spotId : resultData.spotId, categoryId : spotCategory.categoryId, state : spotCategory.state }, data.token, language);

                    if (!response) {
                        break;
                    }
                }

                dispatch({ type : response ? spotActions.postSpot.ACTION_SUCCESS : spotActions.postSpot.ACTION_FAILED, payload : response ? resultData.message : languageService.errors.generic_error[languageService.getLanguage()] });

                resolve(response);
            }).catch(error => {
                dispatch({ type : spotActions.postSpot.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Patch a spot.
export const patchSpot = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: spotActions.patchSpot.ACTION_PENDING });

        axios.patch(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOTS }/${ data.spot.id }`, { data : { spot : data.spot } }, { headers : { 'language' : language, 'authorization' : data.token }})
            .then(async result => {
                const resultData = result.data;
                let response = true;
                
                // Post spot pictures.
                for (let i = 0; i < data.spotPictures.length; i++) {
                    const spotPicture = data.spotPictures[i];

                    if (spotPicture.id !== undefined && spotPicture.id !== null && spotPicture.id > 0) {
                        response = await patchSpotPicture({ id : spotPicture.id, spotId : spotPicture.spotId, instagramAccount : spotPicture.instagramAccount, state : spotPicture.state }, spotPicture.file, data.token, language);
                    } else {
                        response = await postSpotPicture({ spotId : spotPicture.spotId, instagramAccount : spotPicture.instagramAccount, state : spotPicture.state }, spotPicture.file, data.token, language);
                    }

                    if (!response) {
                        break;
                    }
                }

                if (!response) {
                    dispatch({ type : spotActions.patchSpot.ACTION_FAILED, payload : languageService.errors.generic_error[languageService.getLanguage()] });
                    resolve(false);
                    return;
                }
                
                // Delete spot pictures.
                for (let i = 0; i < data.deletedSpotPictures.length; i++) {
                    response = await deleteSpotPicture(data.deletedSpotPictures[i], data.token, language);

                    if (!response) {
                        break;
                    }
                }

                // Post spot categories.
                for (let i = 0; i < data.spotCategories.length; i++) {
                    if (data.spotCategories[i].id !== undefined && data.spotCategories[i].id !== null || data.spotCategories[i].id > 0) {
                        continue;
                    }

                    response = await postSpotCategory({ spotId : data.spot.id, categoryId : data.spotCategories[i].categoryId, state : data.spotCategories[i].state }, data.token, language);

                    if (!response) {
                        break;
                    }
                }

                // Delete spot categories.
                for (let i = 0; i < data.deletedSpotCategories.length; i++) {
                    if (data.deletedSpotCategories[i].id === undefined || data.deletedSpotCategories[i] === null || data.deletedSpotCategories[i].id <= 0) {
                        continue;
                    }

                    response = await deleteSpotCategory(data.deletedSpotCategories[i].id, data.token, language);

                    if (!response) {
                        break;
                    }
                }

                dispatch({ type : response ? spotActions.patchSpot.ACTION_SUCCESS : spotActions.patchSpot.ACTION_FAILED, payload : response ? resultData.message : languageService.errors.generic_error[languageService.getLanguage()] });
                resolve(response);
            }).catch(error => {
                dispatch({ type : spotActions.patchSpot.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Post a spot picture.
const postSpotPicture = (spotPicture, file, token, language) => {
    return new Promise(resolve => {
        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOT_PICTURES }`, { data : { spotPicture : spotPicture }}, { headers : { 'language' : language, 'authorization' : token }})
            .then(async result => {
                resolve(await uploadSpotPicture(result.data.spotPictureId, file, token, language));
            }).catch(error => {
                resolve(false);
            });
    });
}

// Patch a spot picture.
const patchSpotPicture = (spotPicture, file, token, language) => {
    return new Promise(resolve => {
        axios.patch(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOT_PICTURES }/${ spotPicture.id }`, { data : { spotPicture : spotPicture }}, { headers : { 'language' : language, 'authorization' : token }})
            .then(async result => {
                resolve(file !== undefined && file !== null ? await uploadSpotPicture(spotPicture.id, file, token, language) : true);
            }).catch(error => {
                resolve(false);
            });
    });
}

// Delete a spot picture.
const deleteSpotPicture = (id, token, language) => {
    return new Promise(resolve => {
        axios.delete(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOT_PICTURES }/${ id }`, { headers : { 'language' : language, 'authorization' : token }})
            .then(async result => {
                resolve(true);
            }).catch(error => {
                resolve(false);
            });
    });
}

// Upload a spot picture file.
const uploadSpotPicture = (spotPictureId, file, token, language) => {
    let formData = new FormData();
    formData.append(constants.FILE, file);
    
    return new Promise(resolve => {
        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOT_PICTURES }/${ constants.UPLOAD }/${ spotPictureId }`, formData, { headers : { 'language' : language, 'authorization' : token }})
            .then(result => {
                resolve(true);
            }).catch(error => {
                resolve(false);
            });
    });
}

// Get all spot pictures of spot.
export const getAllSpotPicturesBySpotId = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: spotActions.getSpotPictures.ACTION_PENDING });

        axios.get(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOT_PICTURES }/${ data.spotId }/${ constants.ALL }/${ data.lastSpotPictureId }`, { headers : { 'language' : language, 'authorization' : data.token } })
            .then(async result => {
                const resultData = result.data.data;

                for (let i = 0; i < resultData.spotPictures.length; i++) {
                    resultData.spotPictures[i].url = await getSpotPicture({ spotPictureId : resultData.spotPictures[i].id, token : data.token }, language);
                }

                dispatch({ type : spotActions.getSpotPictures.ACTION_SUCCESS, payload : resultData.spotPictures });
                resolve(true);
            }).catch(error => {
                dispatch({ type : spotActions.getSpotPictures.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Get a spot picture file.
const getSpotPicture = (data, language) => {
    return new Promise(resolve => {
        axios.get(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.SPOT_PICTURES }/${ constants.PICTURE }/${ data.spotPictureId }/${ data.token.replace(constants.BEARER_SPACE, constants.EMPTY_STRING) }/${ language }`, { 'responseType' : 'blob' })
            .then(result => {
                resolve(result.data);
            }).catch(error => {
                resolve(null);
            });
    });
}

// Delete a place.
export const deletePlace = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: spotActions.deletePlace.ACTION_PENDING });

        axios.delete(`${ constants.URL }/${ constants.API }/${ constants.V1 }/${ constants.PLACES }/${ data.spotId }`, { headers : { 'language' : language, 'authorization' : data.token } })
            .then(result => {
                dispatch({ type : spotActions.deletePlace.ACTION_SUCCESS, payload : result.data.message});
                dispatch({ type : spotActions.getAllSpots.DELETE, payload : data.index});
                resolve(true);
            }).catch(error => {
                dispatch({ type : spotActions.deletePlace.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Get all spot categories of spot.
export const getAllSpotCategoriesBySpotId = (data, language) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({ type: spotActions.getSpotcategories.ACTION_PENDING });

        axios.get(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.SPOT_CATEGORIES }/${ data.spotId }/${ constants.ALL }/${ data.lastSpotCategoryId }`, { headers : { 'language' : language, 'authorization' : data.token } })
            .then(async result => {
                const resultData = result.data.data;
                dispatch({ type : spotActions.getSpotcategories.ACTION_SUCCESS, payload : resultData.spotCategories });
                resolve(true);
            }).catch(error => {
                dispatch({ type : spotActions.getSpotcategories.ACTION_FAILED, payload : error.response === undefined ? languageService.errors.generic_error[languageService.getLanguage()] : error.response.data.message });
                resolve(false);
            });
    });
}

// Post a spot category.
const postSpotCategory = (spotCategory, token, language) => {
    return new Promise(resolve => {
        axios.post(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.SPOT_CATEGORIES }`, { data : { spotCategory : spotCategory }}, { headers : { 'language' : language, 'authorization' : token }})
            .then(result => resolve(true))
            .catch(error => resolve(false));
    });
}

// Delete a spot category.
const deleteSpotCategory = (spotCategoryId, token, language) => {
    return new Promise(resolve => {
        axios.delete(`${ constants.URL }/${ constants.API }/${ constants.V2 }/${ constants.SPOT_CATEGORIES }/${ spotCategoryId }`, { headers : { 'language' : language, 'authorization' : token }})
            .then(result => resolve(true))
            .catch(error => resolve(false));
    });
}