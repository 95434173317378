import React, { Component } from 'react';

// CSS.
import './Footer.css';

import languageService from '../../utils/language/language';

class Footer extends Component {
    render() {
        return (
            <footer className='footer'>
                <p className='center-text footer-text'>Copyright © { new Date().getFullYear() } { languageService.strings.copyright[languageService.getLanguage()] }</p>
            </footer>
        );
    }
}

export default Footer;