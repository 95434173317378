import userActions from '../constants/user';
import constants from '../../utils/constants';

const defaultState = {
    resetData : { state : constants.INIT, message : constants.EMPTY_STRING },
    loginData : { state : constants.INIT, message : constants.EMPTY_STRING, token : constants.EMPTY_STRING },
};

export const loginReducer = (state = defaultState, action) => {
    switch(action.type) {
        case userActions.login.ACTION_INIT: return Object.assign({}, state, { loginData : { state : constants.INIT, message : constants.EMPTY_STRING, token : constants.EMPTY_STRING }});
        case userActions.login.ACTION_PENDING: return Object.assign({}, state, { loginData : { state : constants.PENDING, message : constants.EMPTY_STRING, token : constants.EMPTY_STRING }});
        case userActions.login.ACTION_SUCCESS: return Object.assign({}, state, { loginData : { state : constants.SUCCESS, message : constants.EMPTY_STRING, token : action.payload }});
        case userActions.login.ACTION_FAILED: return Object.assign({}, state, { loginData : { state : constants.FAILED, message : action.payload, token : constants.EMPTY_STRING }});
        case userActions.login.ACTION_LOGOUT: return Object.assign({}, state, { loginData : { state : constants.INIT, message : constants.EMPTY_STRING, token : constants.EMPTY_STRING }});
        default: return state;
    }
}

export const resetPasswordReducer = (state = defaultState, action) => {
    switch(action.type) {
        case userActions.resetPassword.ACTION_INIT: return Object.assign({}, state, { resetData : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case userActions.resetPassword.ACTION_PENDING: return Object.assign({}, state, { resetData : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case userActions.resetPassword.ACTION_SUCCESS: return Object.assign({}, state, { resetData : { state : constants.SUCCESS, message : action.payload }});
        case userActions.resetPassword.ACTION_FAILED: return Object.assign({}, state, { resetData : { state : constants.FAILED, message : action.payload }});
        case userActions.resetPassword.ACTION_LOGOUT: return Object.assign({}, state, { resetData : { state : constants.INIT, message : constants.EMPTY_STRING }});
        default: return state;
    }
}