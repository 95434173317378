const userActions = {
    login : {
        ACTION_INIT : 'action_login_init',
        ACTION_PENDING : 'action_login_pending',
        ACTION_SUCCESS : 'action_login_success',
        ACTION_FAILED : 'action_login_failed',
        ACTION_LOGOUT : 'action_login_logout',
    },
    resetPassword : {
        ACTION_INIT : 'action_reset_password_init',
        ACTION_PENDING : 'action_reset_password_pending',
        ACTION_SUCCESS : 'action_reset_password_success',
        ACTION_FAILED : 'action_reset_password_failed',
        ACTION_LOGOUT : 'action_reset_password_logout',
    }
}

export default userActions;