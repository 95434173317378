const constants = {
    ADDRESS : 'address',
    BEARER_SPACE : 'Bearer ',
    EMPTY_STRING : '',
    MAPS_REQUEST_TIME : 500, // ms.
    MAX_CATEGORIES_LENGTH : 150,
    MAX_SPOTS_LENGTH : 150,
    MIN_ADDRESS_LENGTH : 8,
    PASSWORD_MIN_LENGTH : 8,
    RE : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    USERNAME_MIN_LENGTH : 6,

    // IDs.
    ADD_BUTTON_CONTAINER : 'add-button-container',
    CATEGORIES_TABLE_BODY : 'categories-table-body',
    CATEGORY_PICTURE_FILE : 'category-picture-file',
    EMAIL : 'email',
    HEADER_ROW : 'header-row',
    LOAD_MORE_SPOTS : 'load-more-spots',
    LOCATION_CONTAINER : 'location-container',
    PASSWORD : 'password',
    SEARCH_BUTTON : 'search-button',
    SEARCH_BUTTON_CONTAINER : 'search-button-container',
    SEARCH_BUTTON_ICON : 'search-button-icon',
    SPOT_PICTURE : 'spot-picture',
    SPOT_PICTURE_FILE : 'spot-picture-file',
    SPOTS_TABLE_BODY : 'spots-table-body',
    CATEGORIES_TABLE_BODY : 'categories-table-body',

    // Languages.
    SPANISH : 'es',
    SPANISH_SPAIN : 'es-ES',
    ENGLISH : 'en',
    ENGLISH_US : 'en-US',

    // Toast delays.
    TOAST_SHORT : 2000,
    TOAST_MEDIUM : 3500,
    TOAST_LONG : 5000,

    // Request states.
    INIT : 'init',
    PENDING : 'pending',
    SUCCESS : 'success',
    FAILED : 'failed',

    // Backend data.
    URL : 'https://api.appspotshot.com',
    //URL : 'http://85.208.22.169:5000',
    //URL : 'http://localhost:5000',
    API : 'api',
    BEARER : 'Bearer ',
    FORGOT_PASSWORD : 'forgotpassword',
    LOGIN : 'login',
    SPOTS : 'spots',
    PLACES : 'places',
    SPOT_CATEGORIES : 'spotcategories',
    SPOT_PICTURES : 'spotpictures',
    PICTURE : 'picture',
    FILE: 'file',
    ALL : 'all',
    FILTER : 'filter',
    UPLOAD : 'upload',
    NOTIFICATIONS : 'notifications',
    CATEGORIES : 'categories',
    V1 : 'v1',
    V2 : 'v2',
    MAPS_SEARCH_URL : 'https://www.google.com/maps/search',

    // Routes.
    ROUTE_LOGIN : '/',
    ROUTE_DASHBOARD : '/dashboard',

    // Roles.
    ROLE_ADMIN : 'admin',
    ROLE_USER : 'user',

    // Side menu items ids.
    ITEM_HOME : 0,
    ITEM_SPOTS : 1,
    ITEM_USERS : 2,
    ITEM_NOTIFICATIONS : 3,
    ITEM_CATEGORIES : 4,

    // Types of view mode.
    MODE_READ : 'read',
    MODE_EDIT : 'edit',
    MODE_INSERT : 'insert',

    // States.
    STATE_OK : 'ok',

    // Spot types.
    SPOT_TYPE_BUSINESS : 'business',
    SPOT_TYPE_NORMAL : 'normal',
    SPOT_TYPE_PREMIUM : 'premium',

    // API Keys.
    API_KEY_GOOGLE_MAPS : 'AIzaSyDZftQotQtsjjuE9VkcEmmRpByOi6zBAss',
}

export default constants;