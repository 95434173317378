import React, { Component } from 'react';

// CSS.
import './CategoryModal.css';

// Constants.
import constants from '../../../utils/constants';

// Services.
import languageService from '../../../utils/language/language';
import utils from '../../../utils/utils';

// Components.
import { Button, Modal, Spinner } from 'react-bootstrap';
import CustomToast from '../../toasts/custom-toast/CustomToast';

class CategoryModal extends Component {
    constructor() {
        super();

        this.state = {
            index : null,
            category : null,
            showToast : false,
            isSaving : false,
            toastMessage : constants.EMPTY_STRING,
            uploadPicture : false,
        }
    }

    // Method that is called when the user changes the name.
    onNameChanged = (event) => {
        const category = this.state.category;
        category.name = event.target.value;

        this.setState({ category : category });
    }

    // Method that is called when the user changes the description.
    onDescriptionChanged = (event) => {
        const category = this.state.category;
        category.description = event.target.value;

        this.setState({ category : category });
    }

    // Method that is called when the user clicks the add picture button.
    onAddPictureClicked = () => {
        document.getElementById(constants.CATEGORY_PICTURE_FILE).click();
    }

    // Method that is called when the file is changed.
    onFileChanged = (event) => {
        if (event.target.files !== null && event.target.files[0] !== null) {
            const category = this.state.category;
            category.file = event.target.files[0];

            this.setState({ category : category, uploadPicture : true });
        }
    }

    // Method that deletes the category picture.
    onDeleteCategoryPictureButtonClicked = () => {
        const category = this.state.category;
        category.file = null;

        this.setState({ category : category, uploadPicture : true });
    }

    // Method that is called when the user clicks the create/edit button.
    onSetCategoryClicked = () => {
        if (!utils.nameIsValid(this.state.category.name)) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_name[languageService.getLanguage()] });
        } else if (!utils.descriptionIsValid(this.state.category.description)) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_description[languageService.getLanguage()] });
        } else if (this.state.uploadPicture && this.state.category.file == null) {
            this.setState({ showToast : true, toastMessage : languageService.errors.invalid_category_picture[languageService.getLanguage()] });
        } else {
            this.setCategory();
        }
    }

    // Method that sets the category.
    setCategory = async () => {
        this.setState({ isSaving : true });
        await this.props.setCategory(this.state.category, this.state.uploadPicture);

        let data;

        this.setState({ isSaving : false });
        switch(this.props.mode) {
            case constants.MODE_INSERT: data = this.props.postState.data; break;
            case constants.MODE_EDIT: data = this.props.patchState.data; break;
            default: data = null;
        }
        
        if (data !== null) {
            if (data.state === constants.FAILED) {
                this.setState({ toastMessage : data.message, showToast : true })
                return;
            }
            
            this.onCancelClicked();
            this.props.showDashboardToast(data.message, constants.TOAST_MEDIUM);

            setTimeout(() => this.props.getAllCategories(0, null) , 500);
        } else {
            this.setState({ toastMessage : languageService.errors.generic_error[languageService.getLanguage()], showToast : true });
        }
    }

    // Method that closes the toast.
    closeToast = () => {
        this.setState({ showToast : false, toastMessage : constants.EMPTY_STRING });
    }

    // Method that is called when the user clicks the cancel button.
    onCancelClicked = async () => {
        if (!this.state.isSaving) {
            await this.props.closeModal();
            this.setState({ index : null, category : null });
        }
    }

    // Method that renders the category data body.
    renderCategoryData() {
        return (
            <div>
                <p className='no-margin no-padding text'><b>{ languageService.strings.category_data[languageService.getLanguage()] }</b></p>

                <div className='no-margin no-padding input-container'>
                    <input type='text' placeholder={ languageService.strings.name[languageService.getLanguage()] } value={ this.state.category.name } onChange={ this.onNameChanged } disabled={ this.props.mode === constants.MODE_READ } />
                </div>

                <div className='no-margin no-padding input-container'>
                    <input type='text' placeholder={ languageService.strings.description[languageService.getLanguage()] } value={ this.state.category.description } onChange={ this.onDescriptionChanged } disabled={ this.props.mode === constants.MODE_READ } />
                </div>
            </div>
        );
    }

    // Method that renders the category picture body.
    renderCategoryPicture() {
        const hasPicture = this.state.category.file !== undefined && this.state.category.file !== null;

        return (
            <div>
                <p className='no-margin no-padding text'><b>{ languageService.strings.picture[languageService.getLanguage()] }</b></p>

                <div className='no-margin no-padding input-container category-pictures'>
                    { this.props.mode === constants.MODE_READ || hasPicture ?  
                        <div className='category-picture-container'>
                            <div className='category-picture-container-element-container'>
                                <img src={ this.state.category.file !== undefined && this.state.category.file !== null ? URL.createObjectURL(this.state.category.file) : '../assets/img_not_found.jpg' } alt='categoryPicture' className={'category-picture-container-element center-element ' + (this.props.mode === constants.MODE_READ ? 'category-picture-container-element-read' : '')}/>
                                { this.props.mode === constants.MODE_READ ? '' : <img src='../assets/delete.svg' alt='delete' className='delete-category-picture float-right cursor-pointer' onClick={ () => this.onDeleteCategoryPictureButtonClicked() }/> }
                            </div>
                        </div>    
                    : 
                        <div>
                            <div className='category-picture-container cursor-pointer' onClick={ () => this.onAddPictureClicked() }><div className='category-picture-container-element-container'><img src='../assets/add.svg' alt='addPicture' className='center-element' id='add-category-picture'/></div></div>                             
                            <input type='file' accept='.jpg, .jpeg, .png' onChange={ this.onFileChanged } className='no-margin no-padding ' id={ constants.CATEGORY_PICTURE_FILE }/>
                        </div>

                    }
                </div>
            </div>
        );
    }

    // Method that renders the primary button.
    renderPrimaryButton = () => {
        let loadingText = constants.EMPTY_STRING;
        let normalText = constants.EMPTY_STRING;

        switch(this.props.mode) {
            case constants.MODE_EDIT:
                normalText = languageService.strings.edit_category[languageService.getLanguage()];
                loadingText = languageService.strings.editting_category[languageService.getLanguage()];
                break;
            case constants.MODE_INSERT:
                normalText = languageService.strings.create_category[languageService.getLanguage()];
                loadingText = languageService.strings.creating_category[languageService.getLanguage()];
                break;
            default: break;
        }

        return (
            <Button variant='primary' onClick={ this.onSetCategoryClicked }>
                { this.state.isSaving ? <span><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='false' /> </span> : '' }
                <span>{ this.state.isSaving ? loadingText : normalText }</span>
            </Button>
        );
    }

    componentDidUpdate() {
        if ((this.props.show || this.isSaving) && this.state.category === null) {
            this.setState( { 
                category : this.props.category !== undefined && this.props.category !== null ? this.props.category : { id : null, name : constants.EMPTY_STRING, description : constants.EMPTY_STRING, pictureUrl : null, file : null, state : constants.STATE_OK },
                uploadPicture : this.props.category === undefined || this.props.category === null,
            });
        }
    }

    render() {
        return(
            <div>
                <Modal show={ (this.props.show || this.state.isSaving) } onHide={ this.onCancelClicked }>
                    <Modal.Header>
                        <Modal.Title>{ this.props.title }</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body className='line'>
                        { this.state.category === null ? '' : this.renderCategoryData() }
                    </Modal.Body>

                    <Modal.Body>
                        { this.state.category === null ? '' : this.renderCategoryPicture() }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant='secondary' onClick={ this.onCancelClicked }>{ languageService.strings.close[languageService.getLanguage()] }</Button>
                        { this.props.mode === constants.MODE_READ ? '' : this.renderPrimaryButton() }
                    </Modal.Footer>
                </Modal>

                <CustomToast show={ this.state.showToast } close={ this.closeToast } delay={ constants.TOAST_MEDIUM } message={ this.state.toastMessage } extraClass={ 'no-left' }/>
            </div>
        );
    }
}

export default CategoryModal;