import notificationsActions from '../constants/notifications';
import constants from '../../utils/constants';

const sendNotificationsDefaultState = {
    data : { state : constants.INIT, message : constants.EMPTY_STRING, },
};

export const sendNotificationAllReducer = (state = sendNotificationsDefaultState, action) => {
    switch(action.type) {
        case notificationsActions.sendAll.ACTION_INIT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        case notificationsActions.sendAll.ACTION_PENDING: return Object.assign({}, state, { data : { state : constants.PENDING, message : constants.EMPTY_STRING }});
        case notificationsActions.sendAll.ACTION_SUCCESS: return Object.assign({}, state, { data : { state : constants.SUCCESS, message : action.payload }});
        case notificationsActions.sendAll.ACTION_FAILED: return Object.assign({}, state, { data : { state : constants.FAILED, message : action.payload }});
        case notificationsActions.sendAll.ACTION_LOGOUT: return Object.assign({}, state, { data : { state : constants.INIT, message : constants.EMPTY_STRING }});
        default: return state;
    }
}