import strings from './strings/strings';
import errors from './strings/errors';
import constants from '../constants';

const getLanguage = () => {
    const language = navigator.language;
    
    switch (language) {
        case constants.SPANISH_SPAIN: return 0;
        case constants.SPANISH: return 1;
        case constants.ENGLISH: return 2;
        case constants.ENGLISH_US: return 3;
        default: return 2;
    }
}

const getLanguageString = () => {
    const language = navigator.language;
    
    switch (language) {
        case constants.SPANISH_SPAIN: return 'es';
        case constants.SPANISH: return 'es';
        case constants.ENGLISH: return'en';
        case constants.ENGLISH_US: return 'en';
        default: return 'en';
    }
}

export default { 
    getLanguage,
    getLanguageString,
    strings,
    errors,
};