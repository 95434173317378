const categoryActions = {
    getAllCategories : {
        ACTION_INIT : 'action_get_all_categories_init',
        ACTION_PENDING : 'action_get_all_categories_pending',
        ACTION_SUCCESS : 'action_get_all_categories_success',
        ACTION_FAILED : 'action_get_all_categories_failed',
        ACTION_LOGOUT : 'action_get_all_categories_logout',
        DELETE : 'action_get_all_categories_delete',
    },
    postCategory : {
        ACTION_INIT : 'action_post_category_init',
        ACTION_PENDING : 'action_post_category_pending',
        ACTION_SUCCESS : 'action_post_category_success',
        ACTION_FAILED : 'action_post_category_failed',
        ACTION_LOGOUT : 'action_set_category_logout',
    },
    patchCategory : {
        ACTION_INIT : 'action_patch_category_init',
        ACTION_PENDING : 'action_patch_category_pending',
        ACTION_SUCCESS : 'action_patch_category_success',
        ACTION_FAILED : 'action_patch_category_failed',
    },
    deleteCategory : {
        ACTION_INIT : 'action_delete_category_init',
        ACTION_PENDING : 'action_delete_category_pending',
        ACTION_SUCCESS : 'action_delete_category_success',
        ACTION_FAILED : 'action_delete_category_failed',
    },
    getCategoryPicture : {
        ACTION_INIT : 'action_get_category_picture_init',
        ACTION_PENDING : 'action_get_category_picture_pending',
        ACTION_SUCCESS : 'action_get_category_picture_success',
        ACTION_FAILED : 'action_get_category_picture_failed',
        ACTION_LOGOUT : 'action_get_category_picture_logout',
    },
}

export default categoryActions;