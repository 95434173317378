import React, { Component } from 'react';

// CSS.
import './Categories.css';

// Services.
import languageService from '../../utils/language/language';

// Constants.
import constants from '../../utils/constants';

// Components.
import { Table, Button } from 'react-bootstrap';
import ContentHeader from '../content-header/ContentHeader';
import CategoryModal from '../modals/category/CategoryModal';

class Categories extends Component {
    constructor() {
        super();

        this.state = {
            showCategoryModal : false,
            mode : constants.MODE_READ,
            modalTitle : constants.EMPTY_STRING,
            selectedCategory : null,
            showMoreCategory : false,
            filter : constants.EMPTY_STRING,
        }
    }

    // Method that is called when the user clicks the new button.
    onNewCategoryButtonClicked = () => {
        this.setState({ showCategoryModal : true, mode : constants.MODE_INSERT, modalTitle : languageService.strings.new_category[languageService.getLanguage()], selectedCategory : null });
    }

    // Method that is called when the user clicks the category to view.
    onCategoryClicked = async (index) => {
        await this.props.getCategoryPicture(this.props.categories.getAll.data.list[index].id, 0);

        const selectedCategory = this.props.categories.getAll.data.list[index];
        selectedCategory.file = this.props.categories.categoryPicture.data.picture;

        this.setState({ showCategoryModal : true, mode : constants.MODE_READ, modalTitle : languageService.strings.view_category[languageService.getLanguage()], selectedCategory : selectedCategory });
    }

    // Method that is called when the user clicks the category edition button.
    onEditCategoryButtonClicked = async (index) => {
        await this.props.getCategoryPicture(this.props.categories.getAll.data.list[index].id, 0);

        const selectedCategory = this.props.categories.getAll.data.list[index];
        selectedCategory.file = this.props.categories.categoryPicture.data.picture;

        this.setState({ showCategoryModal : true, mode : constants.MODE_EDIT, modalTitle : languageService.strings.edit_category[languageService.getLanguage()], selectedCategory : selectedCategory });
    }

    // Method that is called when the user clicks the delete category button.
    onDeleteCategoryButtonClicked = (index) => {
        this.props.onDeleteCategory(index, this.props.categories.getAll.data.list[index].id);
    }

    // Method that loads more categories.
    onLoadMoreCategoriesButtonClicked = async () => {
        const lastElementId = this.props.categories.getAll.data.list[this.props.categories.getAll.data.list.length - 1].id;

        await this.props.getAllCategories(lastElementId, this.state.filter);
        
        this.setState({ showMoreCategories : false });

        const element = document.getElementById(constants.CATEGORIES + lastElementId);
        element.scrollIntoView();
    }

    // Method that closes the category modal.
    onCloseCategoryModalClicked = () => {
        this.setState({ showCategoryModal : false, selectedCategory : null });
    }

    // Method that sets a category (post/patch).
    setCategory = async (category, uploadPicture) => {
        switch(this.state.mode) {
            case constants.MODE_INSERT: return await this.props.onPostCategory(category, uploadPicture);
            case constants.MODE_EDIT: return await this.props.onPatchCategory(category, uploadPicture);
            default: break;
        }
    }

    // Method that renders the content.
    renderContent = () => {
        return (
            <div>
                <Table striped bordered hover className='no-margin no-padding'>
                    <thead>
                        <tr id={ constants.HEADER_ROW }>
                            <th className='cell-w100 center-text'>#</th>
                            <th>{ languageService.strings.name[languageService.getLanguage()] }</th>
                            <th className='no-border-right'>{ languageService.strings.description[languageService.getLanguage()] }</th>
                            <th className='cell-w50'></th>
                            <th className='cell-w50'></th>
                        </tr>
                    </thead>
                    <tbody onScroll={ this.onTableScrolled } id={ constants.CATEGORIES_TABLE_BODY }>
                        { // Draw table rows (categories).
                            this.props.categories.getAll.data.list.map((category, index) => {
                                return (
                                    <tr key={ index } className='cursor-pointer' id={ constants.CATEGORIES + category.id }>
                                        <td className='cell-w100 center-text' onClick={() => this.onCategoryClicked(index) }>{ index + 1 }</td>
                                        <td className='ellipsize-text' onClick={() => this.onCategoryClicked(index) }>{ category.name }</td>
                                        <td className='ellipsize-text' onClick={() => this.onCategoryClicked(index) }>{ category.description }</td>
                                        <td className='cell-w50 cursor-pointer' onClick={() => this.onEditCategoryButtonClicked(index) }><img src='../assets/edit.svg' className='center-element' alt='content'/></td>
                                        <td className='cell-w50 cursor-pointer' onClick={() => this.onDeleteCategoryButtonClicked(index) }><img src='../assets/delete.svg' className='center-element' alt='content'/></td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>

                <br/> 

                <div className={ this.state.showMoreCategories ? '' : ' invisible-element' }>
                    <Button variant='primary' className='button-large sm-text' onClick={ this.onLoadMoreCategoriesButtonClicked }>{ languageService.strings.load_more[languageService.getLanguage()] }</Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className=''>
                <ContentHeader src={ '../assets/categories.svg' } title={ languageService.strings.categories[languageService.getLanguage()] } buttonText={ languageService.strings.new_category[languageService.getLanguage()] } onAddButtonClicked={ this.onNewCategoryButtonClicked } onSearchButtonClicked={ null } showFilter={ false } />

                { /* Content */ }
                { this.props.categories.getAll.data.list === undefined || this.props.categories.getAll.data.list === null || this.props.categories.getAll.data.list.length <= 0 ? <i>{ languageService.strings.categories_not_found[languageService.getLanguage()] }</i> : this.renderContent() }
                
                <CategoryModal show={ this.state.showCategoryModal } closeModal={ this.onCloseCategoryModalClicked } mode={ this.state.mode } title={ this.state.modalTitle } category={ this.state.selectedCategory } setCategory={ this.setCategory } postState={ this.props.categories.post } patchState={ this.props.categories.patch } getAllCategories={ this.props.getAllCategories } showDashboardToast={ this.props.showDashboardToast } />
            </div>

        );
    }
}

export default Categories;