import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Constants.
import constants from './utils/constants';

// Pages.
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';

const Routes = () => {
    return (
        <Switch>
            <Route exact path={ constants.ROUTE_LOGIN } component={ Login } />
            <Route exact path={ constants.ROUTE_DASHBOARD } component={ Dashboard } />
        </Switch>
    );
}

export default Routes;