import React, { Component } from 'react';

// CSS.
import './SpotPictureModal.css';

// Services.
import languageService from '../../../utils/language/language';

// Constants.
import constants from '../../../utils/constants';

// Components.
import { Button, Modal } from 'react-bootstrap';
import CustomToast from '../../toasts/custom-toast/CustomToast';

class SpotPictureModal extends Component {
    onOpen = false;

    constructor() {
        super();

        this.state = {
            instagramAccount : constants.EMPTY_STRING,
            file : null,
            showToast : false,
        }
    }

    // Method that is called when the user changes the file input.
    onFileChanged = (event) => {
        if (event.target.files !== null && event.target.files[0] !== null) {
            this.setState({ file : event.target.files[0] });
        }
    }

    // Method that is called when the user changes the instagram account.
    onInstagramAccountChanged = (event) => {
        this.setState({ instagramAccount : event.target.value })
    }

    // Method that is called when the modal is hidden.
    onHideModal = () => {
        this.setState({ file : null, instagramAccount : constants.EMPTY_STRING });
        this.onOpen = false;
        this.props.closeModal();
    }

    // Method that is called when the user clicks the add button.
    setSpotPicture = () => {
        if (this.state.file === null && (this.props.spotPicture.file === undefined || this.props.spotPicture.file === null) && (this.props.spotPicture.url === undefined || this.props.spotPicture.url === null)) {
            this.setState({ showToast : true });
            return;
        }

        this.props.setSpotPicture(this.props.index, { file : this.state.file, instagramAccount : this.state.instagramAccount });
        this.onHideModal();
    }

    // Method that closes the toast.
    closeToast = () => {
        this.setState({ showToast : false });
    }

    componentDidUpdate() {
        if (this.props.show && this.state.instagramAccount === constants.EMPTY_STRING && this.props.spotPicture.instagramAccount !== constants.EMPTY_STRING && !this.onOpen) {
            this.setState({ instagramAccount : this.props.spotPicture.instagramAccount });
            this.onOpen = true;
        }
    }

    render() {
        return(
            <div>
                <Modal show={ this.props.show } onHide={ this.onHideModal }>
                    <Modal.Header>
                        <Modal.Title>{ languageService.strings.spot_picture[languageService.getLanguage()] }</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <img src={ this.state.file !== null ? URL.createObjectURL(this.state.file) : (this.props.spotPicture !== null && this.props.spotPicture.file !== undefined && this.props.spotPicture.file !== null ? URL.createObjectURL(this.props.spotPicture.file) : (this.props.spotPicture !== null && this.props.spotPicture.url !== undefined && this.props.spotPicture.url !== null ? URL.createObjectURL(this.props.spotPicture.url) : '#')) } 
                            alt='spotPicture' 
                            id={ constants.SPOT_PICTURE } className={'' + (this.state.file !== null || this.props.spotPicture !== null && (this.props.spotPicture.file !== undefined && this.props.spotPicture.file !== null || this.props.spotPicture.url !== undefined && this.props.spotPicture.url !== null) ? '' : 'invisible-element')}
                        />

                        <input type='file' accept='.jpg, .jpeg, .png' onChange={ this.onFileChanged } className='no-margin no-padding' id={ constants.SPOT_PICTURE_FILE }/>

                        <div className='no-margin no-padding input-container'>
                            <input type='text' placeholder={ languageService.strings.instagram_account[languageService.getLanguage()] } value={ this.state.instagramAccount || constants.EMPTY_STRING } onChange={ this.onInstagramAccountChanged } />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant='secondary' onClick={ this.onHideModal }>{ languageService.strings.close[languageService.getLanguage()] }</Button>
                        <Button variant='primary' onClick={ this.setSpotPicture }>{ this.props.spotPicture !== null && this.props.spotPicture.url !== undefined && this.props.spotPicture.url !== null ? languageService.strings.edit[languageService.getLanguage()] : languageService.strings.add[languageService.getLanguage()] }</Button>
                    </Modal.Footer>
                </Modal>

                <CustomToast show={ this.state.showToast  } close={ this.closeToast } delay={ constants.TOAST_MEDIUM } message={ languageService.errors.invalid_file[languageService.getLanguage()] }/>
            </div>
        );
    }

}

export default SpotPictureModal;